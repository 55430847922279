import axios from 'utils/axios';

import values from 'object.values';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import trim from 'lodash.trim';
import logError from 'utils/logError';
import { cleverTapProcess } from 'utils/cleverTap';
// import { API_GET_QUOTE_DETAILS } from '../../constants/api';
import {
  path,
  // getCurrentCustomerInfo,
  // getMagentoSession,
  getJWT,
  getStoreId,
  setCookie,
  sortArrayOfObj
} from 'utils';
import { logEvent } from 'utils/analytics';
import { inventoryCheck } from 'containers/Payment/actions';

import { getCustomerId, getJwtFromEmail, getXHeaderToken } from 'utils/user';
import { setStorage, getStorage, removeStorage } from 'utils/storage';

import {
  // getQuoteFromGuestCartItems,
  formatRemoveFromCart,
  formatCartItem,
  validateCart
} from 'utils/cart';

import { showToast } from 'containers/Account/actions';
import { translate, UNKNOWN_ERROR } from 'constants/language';

import {
  SAVE_ADDRESS_API,
  DELETE_ADDRESS_API,
  GET_QUOTE_API,
  MANAGE_QUOTE_API,
  API_QUOTE_CHANGE_SIZE,
  API_APPLY_COUPON,
  SELECT_ADDRESS_API,
  API_WHATSAPP_OPT,
  GET_ADDRESS_LIST,
  QUOTE_REPLICA,
  API_GET_BAG_COUNT,
  VALIDATE_QUOTE_API,
  GET_TOTALS_API,
  GET_QUOTE_METADATA_API,
  QUOTE_REPLICA_HO
} from '../../constants/api';

import {
  PHONE_CODE,
  CURRENT_LANG_PREFIX,
  SOURCE_OF_ORDER,
  COUNTRY,
  USE_MICROSERVICE,
  BUCKET_URL
} from '../../constants';

export const ACTION_SELECTED_TAB = 'payment/SELECTED_TAB';
export const ACTION_MAKE_PAYMENT_LOADING = 'payment/MAKE_PAYMENT_LOADING';
export const ACTION_MAKE_PAYMENT = 'payment/MAKE_PAYMENT';
export const ACTION_MAKE_PAYMENT_SUCCESS = 'payment/MAKE_PAYMENT_SUCCESS';
export const ACTION_MAKE_PAYMENT_FAILED = 'payment/MAKE_PAYMENT_FAILED';
export const ACTION_SET_ERRORS = 'payment/SET_ERRORS';
export const ACTION_SET_QUOTE_DETAILS = 'shipping/SET_QUOTE_DETAILS';
export const ACTION_SET_UPDATED_QUOTE_DETAILS =
  'shipping/GET_UPDATED_QUOTE_DETAILS';
export const ACTION_SET_ORDER_DETAILS_LOADING = 'payment/_SET_ADDRESS';
export const ACTION_GET_ADDRESS_LOADING = 'checkout/GET_ADDRESS_LOADING';
export const ACTION_SET_ADDRESS = 'confirmation/SET_ADDRESS';
export const ACTION_SET_SELECTED_ADDRESS_ID =
  'confirmation/SET_SELECTED_ADDRESS_ID';
export const ACTION_GET_CITY_LIST = 'checkout/CITY_LIST';

export const ACTION_SAVE_ADDRESS_LOADING = 'checkout/SAVE_ADDRESS_LOADING';
export const ACTION_SAVE_ADDRESS_SUCCESS = 'checkout/SAVE_ADDRESS_SUCCESS';
export const ACTION_SET_ADDRESS_LIST = 'checkout/SET_ADDRESS_LIST';
export const ACTION_SAVE_ADDRESS_FAILED = 'checkout/SAVE_ADDRESS_FAILED';
export const ACTION_PROCEED_TO_PAYMENT = 'checkout/ROCEED_TO_PAYMENT';
export const ACTION_UPDATE_ADDRESS_WITH_MAPPER =
  'checkout/UPDATE_ADDRESS_WITH_MAPPER';

export const ACTION_APPLY_COUPON_LOADING = 'checkout/APPLY_COUPON_LOADING';
export const ACTION_APPLY_COUPON_SUCCESS = 'checkout/APPLY_COUPON_SUCCESS';
export const ACTION_APPLY_COUPON_FAILED = 'checkout/APPLY_COUPON_FAILED';

export const ACTION_MANAGE_METADATA_LODING = 'checkout/MANAGE_METADATA_LODING';
export const ACTION_MANAGE_METADATA_SUCCESS =
  'checkout/MANAGE_METADATA_SUCCESS';
export const ACTION_MANAGE_METADATA_ERROR = 'checkout/MANAGE_METADATA_ERROR';

export const ACTION_ADD_TO_BAG_LOADING = 'checkout/ADD_TO_BAG_LOADING';
export const ACTION_CHANGE_QUOTE_DETAILS = 'checkout/CHANGE_QUOTE_DETAILS';
export const ACTION_SIZE_STOCK_LOADING = 'checkout/SIZE_STOCK_LOADING';
export const ACTION_SIZE_STOCK_LOADED = 'checkout/SIZE_STOCK_LOADED';
export const ACTION_SHOW_TOAST = 'checkout/SHOW_TOAST';
export const ACTION_HIDE_TOAST = 'checkout/HIDE_TOAST';
export const ACTION_RESET = 'checkout/RESET';
export const ACTION_GET_BAG_COUNT_LOADING = 'checkout/GET_BAG_COUNT_LOADING';
export const ACTION_GET_BAG_COUNT_FINISHED = 'checkout/GET_BAG_COUNT_FINISHED';
export const ACTION_GET_TOTALS_FAILED = 'checkout/GET_TOTALS_FAILED';
export const ACTION_GET_TOTALS_LOADING = 'checkout/GET_TOTALS_LOADING';
export const ACTION_GET_TOTALS_SUCCESS = 'checkout/GET_TOTALS_SUCCESS';
// export const ACTION_GET_METADATA = 'checkout/GET_METADATA';

export const ACTION_GET_CITY_LIST_NEW = 'checkout/CITY_LIST_NEW';

export const ACTION_DEFAULT_CITY_DETAILS_LOADING =
  'account/DEFAULT_CITY_DETAILS_LOADING';
export const ACTION_DEFAULT_CITY_DETAILS_FETCHED =
  'account/DEFAULT_CITY_DETAILS_FETCHED';
export const ACTION_DEFAULT_CITY_DETAILS_ERROR =
  'account/DEFAULT_CITY_DETAILS_ERROR';

export const ACTION_UPDATE_ADDRESS = 'checkout/UPDATE_ADDRESS';

export const ACTION_UPDATE_QUOTE_METADATA = 'checkout/UPDATE_QUOTE_METADATA';

export const showCheckoutToast = (
  { type = 'success', content = '', timeOut = 2000 },
  dispatch
) => {
  if (content) {
    dispatch({
      type: ACTION_SHOW_TOAST,
      data: { content, type, timeOut }
    });

    setTimeout(() => {
      dispatch({
        type: ACTION_HIDE_TOAST
      });
    }, timeOut);
  }
};

export function getCartKey({ productId, parentProductId }) {
  return `${productId}_${parentProductId}`;
}

export function resetShippingState(dispatch) {
  dispatch({ type: ACTION_RESET });
}

const getCartItemsFromQuote = products => {
  const productsIds = {};
  products.forEach(item => {
    productsIds[getCartKey(item)] = {
      qty: Number(item.quantity),
      id: item.productId,
      sizeName: item.size,
      size: item.size,
      productId: item.productId,
      parentProductId: item.parentProductId,
      productDetail: item,
      sku: item.sku,
      parentSku: item.parenSku,
      // sizeOptionId: ,
      quantity: Number(item.quantity)
    };
  });
  // setStorage('cartItems', productsIds);
  return productsIds;
};

export async function getCurrentQuoteId() {
  const quoteId = await getStorage('quoteId');
  return quoteId;
}

export async function setCurrentQuoteId(quoteId) {
  if (!quoteId) {
    logError({ message: 'Unknown quote id', customError: 1 });
  }
  await setStorage('quoteId', quoteId);
}

export async function getBagCount(dispatch) {
  const quoteId = await getCurrentQuoteId();
  const storeId = getStoreId();
  const customerId = getCustomerId();
  let bagCount = 0;
  let jwt = getJWT();
  if (!jwt) {
    try {
      await getJwtFromEmail({ email: 'guest@stylishop.com' });
    } catch (e) {
      logError(e);
    }
    jwt = getJWT();
  }

  try {
    dispatch({ type: ACTION_GET_BAG_COUNT_LOADING });

    const reqBody = { quoteId, storeId };
    if (customerId) reqBody.customerId = customerId;
    const response = await axios.post(API_GET_BAG_COUNT(), reqBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: jwt,
        'Content-Type': 'application/json'
      }
    });
    const status = path(['data', 'status'], response);
    if (status) {
      // const itemsCount = path(['data', 'itemsCount'], response);
      const itemsQty = path(['data', 'response', 'itemsQty'], response);
      const giftItemCount =
        path(['data', 'response', 'giftItemCount'], response) || 0;
      bagCount = Number(itemsQty) - Number(giftItemCount);
    }
  } catch (e) {
    logError(e);
  }
  dispatch({ type: ACTION_GET_BAG_COUNT_FINISHED, data: { bagCount } });
}

export async function setSelectedAddressIdToServer(
  { addressId, addressObj = {}, invalidAddressMsg },
  dispatch
) {
  try {
    if (addressId || addressObj) {
      const quoteId = await getCurrentQuoteId();
      const email = addressObj && addressObj.email;
      const requestBody = { quoteId, storeId: getStoreId() };
      const customerId = getCustomerId();

      if (customerId) {
        requestBody.customerId = customerId;
      }
      if (addressId) {
        requestBody.addressId = addressId;
      }
      if (addressObj) {
        requestBody.address = addressObj;
      }

      const result = await axios.post(SELECT_ADDRESS_API(), requestBody, {
        headers: {
          'X-Header-Token': getXHeaderToken(email),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      });

      const respStatusCode = path(['data', 'statusCode'], result);
      if (respStatusCode === '200')
        dispatch({
          type: ACTION_UPDATE_ADDRESS,
          data: {
            shippingAddress: { ...addressObj, firstname: addressObj.firstName }
          }
        });

      if (respStatusCode === '203') {
        showCheckoutToast(
          {
            type: 'error',
            content: invalidAddressMsg
          },
          dispatch
        );
      }

      // window.localStorage.currentQuote = `${cartId}:${addressId}`;
      return result;
    }
  } catch (e) {
    logError(e);
  }
  return {};
}

export async function getMetaData({ quoteId, baseUrl }) {
  let metaData = '';
  try {
    const response = await axios.get(
      `${baseUrl}/getMetaData?quoteId=${quoteId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    metaData = response && response.data;
    // dispatch({ type: ACTION_GET_METADATA, data: response && response.data });
  } catch (e) {
    logError(e);
  }
  return metaData;
}

export async function setMetaData({
  quoteId,
  quoteInfo,
  baseUrl,
  currentQuote
}) {
  // const currentQuote = await getStorage('currentQuote');
  const payload = {
    quoteInfo: quoteInfo.length ? JSON.stringify(quoteInfo) : '',
    event: '',
    misc: JSON.stringify(currentQuote)
  };
  await axios.post(
    `${baseUrl}/saveMetaData`,
    { quoteId, payload },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  // dispatch({ type: ACTION_GET_METADATA, data: response });
}

export async function manageMetaData(
  { quoteId, productCatInfo, isFirstAdd = false },
  dispatch
) {
  dispatch({ type: ACTION_MANAGE_METADATA_LODING });
  try {
    const config = await getStorage('config');
    let quoteInfo = '';
    let result = '';
    let currentQuote = getStorage('currentQuote');
    if (!isFirstAdd) {
      const metaData = await getMetaData({
        quoteId,
        baseUrl: config.cloudFunctionUrl
      });
      quoteInfo = path(['payload', 'quoteInfo'], metaData);
    }
    if (config && config.syncMiscMetadata) {
      const storeId = getStoreId();
      const customerId = getCustomerId();
      const requestBody = { storeId, bagView: 0 };
      if (customerId) {
        requestBody.customerId = customerId;
      } else {
        requestBody.quoteId = quoteId;
      }
      const jwt = getJWT();
      result = await axios.post(GET_QUOTE_API(), requestBody, {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: jwt,
          'Content-Type': 'application/json'
        }
      });
      currentQuote = result.data && result.data.response;
    }
    const updatedQuoteInfo = quoteInfo ? JSON.parse(quoteInfo) : [];
    if (productCatInfo) updatedQuoteInfo.push(productCatInfo);
    await setMetaData({
      quoteId,
      quoteInfo: [...updatedQuoteInfo],
      currentQuote,
      baseUrl: config.cloudFunctionUrl
    });

    dispatch({ type: ACTION_MANAGE_METADATA_SUCCESS });
  } catch (e) {
    dispatch({ type: ACTION_MANAGE_METADATA_ERROR });

    logError(e);
  }
  // return { ...quoteResponse };
}

export async function migrateMetaData({ guestQuoteId, existingQuoteId }) {
  try {
    const config = await getStorage('config');
    const guestMetaData = await getMetaData({
      quoteId: guestQuoteId,
      baseUrl: config.cloudFunctionUrl
    });

    const existingMetaData = await getMetaData({
      quoteId: existingQuoteId,
      baseUrl: config.cloudFunctionUrl
    });

    const guestQuoteInfo =
      path(['payload', 'quoteInfo'], guestMetaData) &&
      JSON.parse(guestMetaData.payload.quoteInfo);
    const existingQuoteInfo =
      path(['payload', 'quoteInfo'], existingMetaData) &&
      JSON.parse(existingMetaData.payload.quoteInfo);

    const mergedQuoteInfo = [...existingQuoteInfo, ...guestQuoteInfo];
    const currentQuote = await getStorage('currentQuote');

    setMetaData({
      quoteId: existingQuoteId,
      quoteInfo: mergedQuoteInfo,
      baseUrl: config.cloudFunctionUrl,
      currentQuote
    });
  } catch (e) {
    logError(e);
  }
}

export async function getUpdateQuoteDetails(options, dispatch) {
  // let orderDetails = {};
  let bagErrors = [];
  let selectedPaymentMethod = '';
  try {
    const {
      bagView = 1,
      cacheOnly = false,
      noCache = false,
      screenName = ''
    } = options;
    const storeId = getStoreId();
    const customerId = getCustomerId() || null;
    const quoteId = (await getCurrentQuoteId()) || null;
    const config = await getStorage('config');
    let paymentHoldCall = {};
    if (window?.location?.search.includes('paymentHoldCall=1')) {
      paymentHoldCall = true;
    } else {
      paymentHoldCall = false;
    }
    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        showCheckoutToast(
          {
            type: 'error',
            content: UNKNOWN_ERROR
          },
          dispatch
        );
        return dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: {} });
      }
      jwt = getJWT();
    }

    dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
    if (!customerId && !quoteId) {
      return dispatch({
        type: ACTION_SET_UPDATED_QUOTE_DETAILS,
        data: {
          quote: {},
          cartItems: [],
          quoteError: false
        }
      });
    }

    if (bagView && cacheOnly) {
      const currentQuote = await getStorage('currentQuote');
      const cartItems = getCartItemsFromQuote(
        (currentQuote && currentQuote.products) || []
      );
      if (currentQuote && !noCache) {
        dispatch({
          type: ACTION_SET_UPDATED_QUOTE_DETAILS,
          data: {
            quote: currentQuote,
            cartItems,
            config
          }
        });
      }
      return {};
    }
    let requestBody = { storeId, bagView };
    if (screenName) {
      requestBody = { ...requestBody, screenName };
    }

    if (paymentHoldCall && quoteId) {
      requestBody = {
        ...requestBody,
        customerId,
        quoteId,
        bagView: 0,
        statusCoinApllied: 0,
        donationAmount: 0.0,
        retryPayment: true
      };
    } else if (customerId) {
      requestBody.customerId = customerId;
    } else {
      requestBody.quoteId = quoteId;
    }
    if (config && config.syncMiscMetadata) {
      if (!options.fromAdd && options.itemChanged) {
        await manageMetaData(
          {
            quoteId
          },
          dispatch
        );
      }
      if (options.isCouponChanged)
        manageMetaData(
          {
            quoteId
          },
          dispatch
        );
    }

    const result = await axios.post(GET_QUOTE_API(), requestBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: jwt,
        'Content-Type': 'application/json'
      }
    });

    getBagCount(dispatch);

    let response = (result.data && result.data.response) || result.data; // slight confustion with the api
    const { firstFreeShipping } = response;
    response = { ...response, ...firstFreeShipping };

    if (response && response.quoteId) {
      const cartItems = getCartItemsFromQuote(response.products);
      selectedPaymentMethod = response.selectedPaymentMethod;
      dispatch({
        type: ACTION_SET_UPDATED_QUOTE_DETAILS,
        data: {
          quote: response,
          cartItems,
          config,
          firstFreeShipping
        }
      });
      if (response.quoteId) setCurrentQuoteId(response.quoteId);
      bagErrors = validateCart(response);
      await setStorage('currentQuote', response);
    } else {
      if (
        Number(response.statusCode) === 404 ||
        Number(response.statusCode) === 201 ||
        Number(response.statusCode) === 203 ||
        Number(response.statusCode) === 202
      ) {
        await removeStorage('quoteId');
        await removeStorage('currentQuote');
        await removeStorage('cartItems');
      }
      dispatch({
        type: ACTION_SET_UPDATED_QUOTE_DETAILS,
        data: {
          quote: response,
          cartItems: [],
          config,
          quoteError: response.status,
          firstFreeShipping
        }
      });
    }
    if (options.fromPlaceOrder) {
      return { selectedPaymentMethod };
    }
  } catch (error) {
    logError(error);
    dispatch({
      type: ACTION_SET_UPDATED_QUOTE_DETAILS,
      data: {
        quote: {},
        cartItems: [],
        quoteError: true
      }
    });
  }
  return { bagErrors };
}
export const fetchAddresses = async ({ customerId, storeId }) => {
  try {
    if (!customerId || !storeId) {
      return {};
    }

    const result = await axios.post(
      GET_ADDRESS_LIST({ customerId }),
      { customerId, storeId: getStoreId() },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    const response = (result.data && result.data.response) || result.data; // slight confustion with the api
    if (response.addresses) {
      let enabledAddresses = [];
      let disabledAddresses = [];
      response.addresses.forEach(address => {
        if (address.country === COUNTRY.toUpperCase()) {
          enabledAddresses.push({ ...address, enabled: true });
        } else {
          disabledAddresses.push({ ...address, enabled: false });
        }
      });

      enabledAddresses = sortArrayOfObj(enabledAddresses, 'addressId', 'desc');
      disabledAddresses = sortArrayOfObj(
        disabledAddresses,
        'addressId',
        'desc'
      );

      const addresses = enabledAddresses.concat(disabledAddresses);
      return { addresses };
    }
    return response;
  } catch (e) {
    logError(e);
  }
  return {};
};
export async function reloadAddress(options, dispatch) {
  let addresses = [];

  try {
    dispatch({ type: ACTION_GET_ADDRESS_LOADING });
    const customerId = getCustomerId();
    const quoteId = await getStorage('quoteId');
    const guestAddress = await getStorage('guestAddress');
    if (customerId) {
      const storeId = getStoreId();
      const { addresses: customerAddresses = [] } = await fetchAddresses({
        customerId,
        storeId
      });
      addresses = customerAddresses;
      // dispatch({ type: ACTION_SET_ADDRESS_LIST, data: { addresses } });
    } else if (quoteId && guestAddress) {
      addresses.push(guestAddress);
    }
    dispatch({
      type: ACTION_SET_ADDRESS_LIST,
      data: { addresses, guestAddress }
    });
  } catch (e) {
    logError(e);
  }
  return addresses;
}

export async function setSelectedAddressId(
  { addressId, moveTo, selectedAddress, invalidAddressMsg },
  dispatch,
  history
) {
  dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
  try {
    // const { } = selectedAddress;
    const addressObj = {
      ...pick(selectedAddress, [
        'area',
        'buildingNumber',
        'city',
        'country',
        'defaultAddress',
        'firstName',
        'lastName',
        'mobileNumber',
        'region',
        'regionId',
        'streetAddress',
        'telephone',
        'email',
        'latitude',
        'longitude'
      ])
    };
    const result = await setSelectedAddressIdToServer(
      { addressId, addressObj, invalidAddressMsg },
      dispatch
    );
    if (result.data.status) {
      dispatch({ type: ACTION_SET_SELECTED_ADDRESS_ID, data: { addressId } });

      dispatch({
        type: ACTION_CHANGE_QUOTE_DETAILS,
        data: { selectedAddressId: addressId }
      });
    }
  } catch (e) {
    logError(e);
  }

  await getUpdateQuoteDetails({ bagView: 0 }, dispatch);
  if (history) {
    history.push(moveTo);
  }

  return {};
}

export async function getCitiesAndAreas({ address }, dispatch) {
  if (COUNTRY.toLowerCase() === 'in') return;
  try {
    dispatch({
      type: ACTION_DEFAULT_CITY_DETAILS_LOADING
    });
    const lang = CURRENT_LANG_PREFIX;
    const cntry =
      address && address.addressId ? address.country.toLowerCase() : COUNTRY;

    const addressResponse = await axios.get(
      `${BUCKET_URL}/address_${COUNTRY}.json?cf=2`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const provinceData = path(
      ['data', 'provinces', cntry.toUpperCase()],
      addressResponse
    );
    dispatch({
      type: ACTION_GET_CITY_LIST_NEW,
      data: { provinceData, cntry, lang }
    });
    const statusCode = path(['status'], addressResponse);
    const data = path(['data'], addressResponse);

    if (statusCode === 200) {
      dispatch({
        type: ACTION_DEFAULT_CITY_DETAILS_FETCHED,
        data
      });
    } else {
      const toastContent = UNKNOWN_ERROR;
      dispatch({ type: ACTION_DEFAULT_CITY_DETAILS_ERROR });
      showCheckoutToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_DEFAULT_CITY_DETAILS_ERROR });

    showCheckoutToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function getCitiesAndAreasOld({ address }, dispatch) {
  const lang = CURRENT_LANG_PREFIX;
  const cntry =
    address && address.addressId ? address.country.toLowerCase() : COUNTRY;
  const citiesResult = await axios.get(
    `/resources/${cntry}/cities${lang === 'ar' ? '-ar' : '-en'}.json`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const { provinces, areas } = citiesResult.data;
  dispatch({
    type: ACTION_GET_CITY_LIST,
    data: { provinces, areas, addressMapperModification: {} }
  });
  try {
    if (address) {
      if (
        (lang === 'ar' && address.city.match(/[a-z]/)) ||
        (lang !== 'ar' && !address.city.match(/[a-z]/))
      ) {
        const citiesOtherResult = await axios.get(
          `/resources/${cntry}/cities${lang === 'ar' ? '-en' : '-ar'}.json`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        const {
          provinces: provincesOther,
          areas: areasOther
        } = citiesOtherResult.data;

        const cityObject = values(
          path(
            [cntry.toUpperCase(), address.regionId, 'cities'],
            provincesOther
          ) || {}
        ).find(city => {
          return address.city === city.name;
        });
        let areaObject = {};
        if (cityObject.name && areasOther[cityObject.id]) {
          areaObject = values(areasOther[cityObject.id].areas).find(area => {
            return address.area === area.name;
          });
        }

        if (isEmpty(areaObject)) {
          areaObject = cityObject;
        }
        const city = path(
          [
            cntry.toUpperCase(),
            address.regionId,
            'cities',
            cityObject.id,
            'name'
          ],
          provinces
        );
        dispatch({
          type: ACTION_UPDATE_ADDRESS_WITH_MAPPER,
          data: {
            addressMapperModification: {
              ...address,
              region:
                path(
                  [cntry.toUpperCase(), address.regionId, 'name'],
                  provinces
                ) || '',
              city,
              area:
                path([cityObject.id, 'areas', areaObject.id, 'name'], areas) ||
                city
            }
          }
        });
      }
    }
  } catch (e) {
    logError(e);
  }
}

export async function manageQuote(
  {
    address,
    quote,
    isGuestUser,
    cartItem,
    cartItems,
    fromAdd = false
    // accountDispatch
  },
  dispatch
) {
  // const siteConfig = await getStorage('siteConfig');

  // if (!siteConfig && accountDispatch)
  //   await getStyliConfig({}, accountDispatch);

  let quoteId = await getCurrentQuoteId();
  const customerId = getCustomerId();
  let addToQuoteProductsRequests = [];
  if (!quoteId && quote) {
    addToQuoteProductsRequests = quote.products.map(product => {
      return USE_MICROSERVICE
        ? {
            ...pick(product, [
              'sku',
              'parentSku',
              'quantity',
              'caSource',
              'caSourceType',
              'caSourceValue'
            ]),
            overrideQuantity: true
          }
        : {
            ...pick(product, [
              'sizeOptionId',
              'productId',
              'parentProductId',
              'quantity',
              'caSource',
              'caSourceType',
              'caSourceValue'
            ]),
            overrideQuantity: false
          };
    });
  } else if (cartItem) {
    addToQuoteProductsRequests = USE_MICROSERVICE
      ? [
          {
            ...pick(cartItem, [
              'sku',
              'parentSku',
              'quantity',
              'caSource',
              'caSourceType',
              'caSourceValue',
              'caBannerPromoName'
            ]),
            overrideQuantity: true
          }
        ]
      : [
          {
            ...pick(cartItem, [
              'sizeOptionId',
              'productId',
              'parentProductId',
              'quantity',
              'caSource',
              'caSourceType',
              'caSourceValue',
              'caBannerPromoName'
            ]),
            overrideQuantity: true
          }
        ];
  }

  const storeId = getStoreId();
  let jwt = getJWT();

  if (!jwt) {
    try {
      await getJwtFromEmail({ email: 'guest@stylishop.com' });
    } catch (e) {
      showCheckoutToast(
        {
          type: 'error',
          content: UNKNOWN_ERROR
        },
        dispatch
      );
      return dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: {} });
    }
    jwt = getJWT();
  }

  const data = {
    storeId,
    source: SOURCE_OF_ORDER
  };

  if (!customerId && fromAdd && VALIDATE_QUOTE_API() && quoteId) {
    const result = await axios({
      method: 'get',
      headers: {
        token: jwt,
        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
        // 'X-Requested-With': 'XMLHttpRequest'
      },
      url: `${VALIDATE_QUOTE_API()}?quoteId=${quoteId}`
    });

    const validatedQuoteId = path(['data', 'quoteId'], result);
    const isValidQuote = path(['data', 'response', 'isValid'], result);

    quoteId = isValidQuote ? validatedQuoteId : '';
  }

  if (quoteId && !customerId) {
    data.quoteId = quoteId;
  }
  if (customerId) {
    data.customerId = customerId;
  }

  if (addToQuoteProductsRequests.length) {
    data.addToQuoteProductsRequests = addToQuoteProductsRequests;
  }

  if (jwt) {
    try {
      const result = await axios({
        method: quoteId && !addToQuoteProductsRequests.length ? 'put' : 'post',
        url: MANAGE_QUOTE_API(),
        data,
        headers: {
          token: jwt,
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
          // 'X-Requested-With': 'XMLHttpRequest'
        }
      });
      dispatch({
        type: ACTION_ADD_TO_BAG_LOADING,
        data: { loading: false, cartItems }
      });
      const { quoteId: newQuoteId, status, statusCode, statusMsg = '' } =
        result.data || {};
      if (status && newQuoteId > 0) {
        await setCurrentQuoteId(newQuoteId);
        if (!fromAdd) {
          await getUpdateQuoteDetails(
            { bagView: 0, fromAdd, itemChanged: true },
            dispatch
          );
        }
        if (!cartItem) {
          if (isGuestUser) {
            await setStorage('guestAddress', address);
            setCookie('r_email', address.email, 365);
            // await removeStorage('cartItems');
            showCheckoutToast(
              {
                type: 'success',
                content: translate('Address Added Successfully')
              },
              dispatch
            );
            dispatch({
              type: ACTION_SAVE_ADDRESS_SUCCESS,
              data: {
                status,
                showAddressSuccessMessage: 'added',
                addressId: Math.random()
              }
            });
            await reloadAddress({}, dispatch);
          }
        }
      } else if (statusCode === '201') {
        showCheckoutToast(
          {
            type: 'error',
            content: UNKNOWN_ERROR
          },
          dispatch
        );
        dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: {} });
        if (isGuestUser) {
          await removeStorage('quoteId');
        }
        logError(new Error(`${statusMsg}: ${quoteId}`));
      }
    } catch (e) {
      logError(e);
      showCheckoutToast(
        {
          type: 'error',
          content: UNKNOWN_ERROR
        },
        dispatch
      );
      dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: {} });
      dispatch({
        type: ACTION_ADD_TO_BAG_LOADING,
        data: { loading: false }
      });
    }
  }
  return {};
}

export async function saveAddress(
  {
    payload,
    forProfile = false,
    hideToast = false,
    invalidAddressMsg,
    invalidSaveAddressMsg
  },
  dispatch
) {
  dispatch({ type: ACTION_SAVE_ADDRESS_LOADING, data: { loading: true } });
  const customerId = getCustomerId();
  const {
    fullname = '',
    country,
    landMark,
    selectedProvinces,
    streetAddress = '',
    mobileNumber,
    buildingNumber,
    addressId,
    defaultAddress,
    area,
    city,
    region,
    regionId,
    latitude,
    longitude
  } = payload;
  const names = trim(fullname).split(' ');
  const lastName = names.length > 1 ? names.pop() : '.';
  const firstname = names.join(' ');
  const data = {
    area: path(['area', 'name'], selectedProvinces) || area,
    buildingNumber,
    city: path(['city', 'name'], selectedProvinces) || city,
    country,
    defaultAddress,
    firstName: firstname,
    landMark,
    lastName,
    mobileNumber: `${PHONE_CODE[country]} ${Number(mobileNumber)}`,
    region: path(['province', 'name'], selectedProvinces) || region,
    regionId: String(path(['province', 'id'], selectedProvinces)) || regionId,
    streetAddress,
    telephone: `${PHONE_CODE[country]}${mobileNumber}`,
    latitude,
    longitude
  };
  if (addressId) {
    data.addressId = addressId;
  }
  if (customerId) {
    data.customerId = customerId;
  } else {
    data.email = payload.email;
    data.defaultAddress = true;
    await setStorage('guestAddress', data);

    setCookie('r_email', data.email, 365);
    if (!forProfile) {
      const result = await setSelectedAddressIdToServer(
        { addressObj: data, addressId, invalidAddressMsg },
        dispatch
      );
      const { statusCode, status } = result.data || {};
      if (statusCode === '200') {
        if (!hideToast) {
          showCheckoutToast(
            {
              type: 'success',
              content: translate('Address Added Successfully')
            },
            dispatch
          );
          window.scroll({
            top: window.scrollY + 600,
            left: 0,
            behavior: 'smooth'
          });
        }
        dispatch({
          type: ACTION_SAVE_ADDRESS_SUCCESS,
          data: {
            status,
            showAddressSuccessMessage: 'added',
            addressId: Math.random()
          }
        });
      }
      return dispatch({
        type: ACTION_SET_ADDRESS_LIST,
        data: { addresses: [], guestAddress: data }
      });
      // return manageQuote({ address: data, quote, isGuestUser: true }, dispatch);
    }
  }

  try {
    const result = await axios({
      method: addressId ? 'put' : 'post',
      url: SAVE_ADDRESS_API(),
      data,
      headers: {
        token: getJWT(),
        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
    const { status, statusMsg, response, statusCode } = result.data;
    if (statusCode === '203') {
      showToast(
        {
          type: 'error',
          content: invalidSaveAddressMsg
        },
        dispatch
      );
    }

    if (status) {
      const newAddressId = path(['address', 'addressId'], response);

      try {
        if (!hideToast) {
          showCheckoutToast(
            {
              type: 'success',
              content: addressId
                ? translate('Address Updated Successfully')
                : translate('Address Added Successfully')
            },
            dispatch
          );
        }
        dispatch({
          type: ACTION_SAVE_ADDRESS_SUCCESS,
          data: {
            status,
            showAddressSuccessMessage: addressId ? 'updated' : 'added',
            addressId: newAddressId || addressId
          }
        });
        await reloadAddress({}, dispatch);

        if (!forProfile) {
          // await reloadAddress({}, dispatch);

          await setSelectedAddressId(
            {
              selectedAddress: data,
              addressId: newAddressId || addressId,
              invalidAddressMsg
            },
            dispatch
          );
        }
      } catch (e) {
        logError(e);
      }
    } else {
      showCheckoutToast(
        {
          type: 'error',
          content: statusMsg || UNKNOWN_ERROR
        },
        dispatch
      );
      dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: { statusMsg } });
    }
  } catch (e) {
    showCheckoutToast(
      {
        type: 'error',
        content: UNKNOWN_ERROR
      },
      dispatch
    );
    dispatch({ type: ACTION_SAVE_ADDRESS_FAILED, data: {} });
  }
  return {};
}

export async function deleteAddress({ addressId }, dispatch) {
  const result = await axios({
    method: 'delete',
    url: DELETE_ADDRESS_API(),
    data: { addressId, customerId: getCustomerId() },
    headers: {
      token: getJWT(),
      'X-Header-Token': getXHeaderToken(),
      'Content-Type': 'application/json'
      // 'X-Requested-With': 'XMLHttpRequest'
    }
  });
  const status = path(['data', 'status'], result);
  if (status) {
    await reloadAddress({}, dispatch);
  } else {
    // alert('Unable to delete the address');
  }
  return {};
}

export async function setShippingAddressInformation({
  shippingAddress,
  billingAddress
}) {
  const currentLanguage = CURRENT_LANG_PREFIX;
  // dispatch = () => {}
  const result = await axios({
    method: 'post',
    url: `/${currentLanguage}/rest/${currentLanguage}/V1/carts/mine/shipping-information`,
    data: {
      addressInformation: {
        billing_address: billingAddress,
        shipping_address: shippingAddress,
        shipping_carrier_code: 'clickpost',
        shipping_method_code: 'clickpost'
      }
    },
    headers: {
      // 'X-Requested-With': 'XMLHttpRequest'
    }
  });
  return result;
  // const { provinces, areas } = result;
  // dispatch({ type: ACTION_SAVE_ADDRESS_SUCCESS, data: { provinces, areas } });
}

export function proceedToPayment(dispatch) {
  dispatch({
    type: ACTION_PROCEED_TO_PAYMENT
  });
}

export async function getQuoteMetadata(dispatch) {
  let quoteMetadata = [];
  const quoteId = await getCurrentQuoteId();
  const jwt = getJWT();
  if (quoteId) {
    try {
      const response = await axios.get(
        `${GET_QUOTE_METADATA_API()}?quoteId=${quoteId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-header-token': getXHeaderToken(),
            token: jwt
          }
        }
      );

      quoteMetadata = path(['data', 'response', 'metadata'], response) || [];
    } catch (e) {
      logError(e);
    }
  }
  dispatch({ type: ACTION_UPDATE_QUOTE_METADATA, data: quoteMetadata });
}

export async function addToCart(
  {
    productId,
    parentProductId,
    productDetail,
    sizeOptionId,
    quantity,
    quote,
    productCatInfo,
    sku,
    parentSku,
    caSource,
    caSourceType,
    caSourceValue,
    caSearchId,
    caBannerPromoName,
    accountDispatch
  },
  dispatch
) {
  try {
    const customerId = getCustomerId();
    const cartItem = {
      productId,
      parentProductId,
      productDetail,
      sizeOptionId,
      quantity,
      sku,
      parentSku,
      caSource,
      caSourceType,
      caSourceValue,
      caSearchId,
      caBannerPromoName
    };
    const cartItems = getCartItemsFromQuote((quote && quote.products) || []);
    const config = await getStorage('config');

    const key = getCartKey({ productId, parentProductId });
    cartItems[key] = cartItem;
    dispatch({ type: ACTION_ADD_TO_BAG_LOADING, data: { loading: true } });
    const address = await getStorage('guestAddress');
    await manageQuote(
      {
        address,
        cartItem,
        isGuestUser: !customerId,
        cartItems,
        fromAdd: true,
        accountDispatch
      },
      dispatch
    );
    let cleverTapData = {};
    if (productDetail) {
      const selectedProductData = await formatCartItem(
        productDetail,
        quantity,
        productId,
        {
          productCatInfo
        }
      );
      cleverTapData = {
        product_name: productDetail.name,
        sku_id: productDetail.sku,
        cat_name: productDetail?.categories?.level3[0],
        cat_id: productDetail.categoryIds ? productDetail?.categoryIds[3] : '',
        parent_cat_name: productDetail?.categories?.level2[0],
        parent_cat_id: productDetail.categoryIds
          ? productDetail?.categoryIds[2]
          : '',
        super_cat_name: productDetail?.categories?.level1[0],
        super_cat_id: productDetail.categoryIds
          ? productDetail?.categoryIds[1]
          : '',
        price: Number(
          productDetail?.prices?.specialPrice
            ? productDetail?.prices?.specialPrice
            : productDetail?.prices?.price
        ),
        size: selectedProductData.variant
      };
    }
    // REQ1: Add to cart event
    logEvent({
      eventName: 'add_to_cart',
      data: {
        event_category: 'cart_update',
        event_value: 'from_pdp_main',
        value: Number(
          productDetail.prices.specialPrice || productDetail.prices.price
        ),
        items: await formatCartItem(productDetail, quantity, productId, {
          productCatInfo
        })
      },
      cleverTapData,
      screenName: 'Checkout_Review'
    });
    const quoteId = await getCurrentQuoteId();
    if (config && config.syncMiscMetadata) {
      await manageMetaData(
        {
          quoteId,
          productCatInfo
          // isFirstAdd: !(quote && quote.products && quote.products.length > 1)
        },
        dispatch
      );
    }
  } catch (e) {
    logError(e);
  }
}

export async function removeFromCart(toDeleteItem, dispatch) {
  const { parentQuoteItemId, sku } = toDeleteItem;
  try {
    const quoteId = await getCurrentQuoteId();
    const jwtToken = getJWT();
    // const cartItems = (await getStorage('cartItems')) || {};
    // const key = getCartKey({ productId, parentProductId });
    // const toDeleteItem = cartItems[key];
    const customerId = getCustomerId();

    if (quoteId && jwtToken) {
      const requestBody = USE_MICROSERVICE
        ? {
            skus: [sku],
            quoteId,
            storeId: getStoreId(),
            customerId
          }
        : {
            parentQuoteItemIds: [Number(parentQuoteItemId)],
            quoteId,
            storeId: getStoreId()
          };

      if (customerId) {
        requestBody.customerId = customerId;
      }
      dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
      await axios.delete(MANAGE_QUOTE_API(), {
        data: requestBody,
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
      await removeStorage('currentQuote');
    }
    getUpdateQuoteDetails({ bagView: 1, itemChanged: true }, dispatch);
    if (toDeleteItem) {
      // REQ6: remove from cart
      const removeFromCartObj = {
        eventName: 'remove_from_cart',
        data: {
          event_category: 'cart_update',
          event_value: 'from_cart_normal',
          value: Number(
            toDeleteItem.prices.specialPrice || toDeleteItem.prices.price
          ),
          items: await formatRemoveFromCart(toDeleteItem, toDeleteItem.quantity)
        },
        screenName: 'Checkout_Review'
      };
      logEvent(removeFromCartObj);
    }
  } catch (e) {
    logError(e);
  }
}

export async function updateSizeStock({ sizeArray }, dispatch) {
  const quoteId = await getCurrentQuoteId();
  if (quoteId) {
    dispatch({ type: ACTION_SIZE_STOCK_LOADING });
    const result = await inventoryCheck({
      productArray: sizeArray,
      returnData: true
    });
    if (result) {
      dispatch({ type: ACTION_SIZE_STOCK_LOADED, data: { sizeStock: result } });
    }
  }
}

export async function changeSize({ product, selectedSize }, dispatch) {
  try {
    const {
      // productId,
      parentProductId,
      sku,
      parentSku,
      quantity,
      parentQuoteItemId
    } = product;
    const {
      productId: sizeProductId,
      sizeOptionId,
      sku: selectedSku
    } = selectedSize;
    const quoteId = await getCurrentQuoteId();
    const customerId = getCustomerId();

    const jwtToken = getJWT();

    if (quoteId && jwtToken) {
      const requestBody = USE_MICROSERVICE
        ? {
            addRequest: {
              overrideQuantity: true,
              sku: selectedSku,
              parentSku,
              quantity: Number(quantity)
            },
            skuToDelete: sku,
            quoteId,
            storeId: getStoreId()
          }
        : {
            addRequest: {
              overrideQuantity: true,
              parentProductId: Number(parentProductId),
              productId: Number(sizeProductId),
              quantity: Number(quantity),
              sizeOptionId
            },
            parentQuoteItemToDelete: Number(parentQuoteItemId),
            quoteId,
            storeId: getStoreId()
          };

      if (customerId) {
        requestBody.customerId = customerId;
      }
      dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
      await axios.put(API_QUOTE_CHANGE_SIZE(), requestBody, {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
    }
    getUpdateQuoteDetails(
      { bagView: 1, noCache: true, itemChanged: true },
      dispatch
    );
  } catch (e) {
    logError(e);
  }
}

export async function changeQuantity({ product, selectedQty }, dispatch) {
  try {
    const { parentQuoteItemId, sku } = product;
    const quoteId = await getCurrentQuoteId();
    const customerId = getCustomerId();
    const jwtToken = getJWT();

    if (quoteId && jwtToken) {
      const requestBody =
        USE_MICROSERVICE || true
          ? {
              sku,
              quantity: Number(selectedQty),
              quoteId,
              storeId: getStoreId()
            }
          : {
              parentQuoteItemId: Number(parentQuoteItemId),
              quantity: Number(selectedQty),
              quoteId,
              storeId: getStoreId()
            };
      if (customerId) {
        requestBody.customerId = customerId;
      }

      dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
      await axios.put(MANAGE_QUOTE_API(), requestBody, {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
    }
  } catch (e) {
    logError(e);
    showToast(
      {
        type: 'error',
        content: UNKNOWN_ERROR
      },
      dispatch
    );
  }
  getUpdateQuoteDetails(
    { bagView: 1, noCache: true, itemChanged: true },
    dispatch
  );
}

export async function applyCoupon(
  { couponValue, removeCoupon = false },
  dispatch
  // accountDispatch
) {
  try {
    const quoteId = await getCurrentQuoteId();
    // let toastMsg = translate('Coupon Applied Successfully');
    // let toastType = 'success';
    const reqBody = { quoteId, storeId: getStoreId() };

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }
    dispatch({ type: ACTION_APPLY_COUPON_LOADING });
    let result;
    if (removeCoupon) {
      result = await axios.delete(API_APPLY_COUPON(), {
        data: reqBody,
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
      // toastMsg = translate('Coupon Removed Successfully');
      // toastType = 'error';
    } else {
      reqBody.coupon = couponValue;
      result = await axios.post(API_APPLY_COUPON(), reqBody, {
        headers: {
          Token: jwt,
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
    }
    const { status, statusCode, statusMsg } = result.data || {};
    if (status) {
      logEvent({
        eventName: 'couponcode_success',
        data: {
          promoName: couponValue
        },
        cleverTapData: {
          user_id: getCustomerId(),
          coupon_name: couponValue,
          source_screen_name: 'Bag_Home'
        },
        isOnlyCleverTap: true,
        screenName: 'Checkout_Payment'
      });

      dispatch({
        type: ACTION_APPLY_COUPON_SUCCESS,
        data: { removeCouponSuccess: removeCoupon }
      });
      setTimeout(() => {
        getUpdateQuoteDetails({ bagView: 1, isCouponChanged: true }, dispatch);
      }, 1000);
    } else {
      logEvent({
        eventName: 'couponcode_failed',
        data: {
          promoName: couponValue
        },
        cleverTapData: {
          user_id: getCustomerId(),
          coupon_name: couponValue,
          source_screen_name: 'Bag_Home'
        },
        isOnlyCleverTap: true,
        screenName: 'Checkout_Payment'
      });
      dispatch({
        type: ACTION_APPLY_COUPON_FAILED,
        data: { statusMsg: statusCode === '300' ? statusMsg : '' }
      });
    }
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_APPLY_COUPON_FAILED });
  }
}

export async function createReplica(
  {
    orderId,
    isReOrder,
    orderNumber,
    responseMessage,
    paymentStatus,
    paymentId,
    method,
    statusVal,
    holdStatus
  },
  dispatch,
  history
) {
  try {
    const reqBody = {
      orderId,
      storeId: getStoreId(),
      tabbyPaymentId: paymentId,
      paymentId
    };
    if (method && method === 'BNPL') {
      delete reqBody.orderId;
    }
    const result = await axios.post(QUOTE_REPLICA(), reqBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });
    const { data: { quoteId } = {} } = result;
    if (quoteId) {
      await setCurrentQuoteId(quoteId);
    }
    cleverTapProcess({
      eventName: 'order_failed',
      cleverTapData: {
        order_id: orderId,
        country: COUNTRY
      }
    });
    if (
      (statusVal === 'cancel' && holdStatus === true) ||
      holdStatus === 'true'
    ) {
      history.replace(
        `/${CURRENT_LANG_PREFIX}/checkout/payment?paymentHoldCall=1`
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }
  } catch (e) {
    logError(e);
  }
  if (history) {
    if (isReOrder) {
      history.replace(`/${CURRENT_LANG_PREFIX}/checkout/cart`);
    } else {
      history.replace(
        paymentId
          ? `/${CURRENT_LANG_PREFIX}/checkout/payment?payment_id=${paymentId}&paymentStatus=${paymentStatus}`
          : `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${responseMessage}&paymentStatus=${paymentStatus}`
      ); // there is an issue
    }
  }
}

export async function createReplicaPaymentHold(
  {
    orderId,
    failedPaymentMethod,
    customerIdentification,
    status,
    orderNumber,
    responseMessage,
    paymentStatus,
    paymentId
  },
  dispatch,
  history
) {
  try {
    const customerId = getCustomerId() || customerIdentification;
    const reqBody = {
      orderId,
      customerId: customerId?.toString(),
      failedPaymentMethod,
      storeId: getStoreId()?.toString()
    };
    const configVal = await getStorage('config');
    const { paymentFailedHold } = configVal;
    const lang = COUNTRY;
    const { msite_hold_order: msiteHoldorder } =
      paymentFailedHold?.[lang] || {};
    const shouldHoldPayment = msiteHoldorder || msiteHoldorder === 'true';
    //  const urlParams = new URLSearchParams(window.location.search);
    //  const orderNumberUrl = urlParams.get('orderNumber');
    //  const responseMessageUrl = urlParams.get('response_message');
    //  const paymentStatusUrl = urlParams.get('paymentStatus');
    const dataStore = await getStorage('profile');
    if (!shouldHoldPayment) {
      history.replace(
        paymentId
          ? `/${CURRENT_LANG_PREFIX}/checkout/payment?payment_id=${paymentId}&paymentStatus=${paymentStatus}`
          : `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${responseMessage}&paymentStatus=${paymentStatus}`
      );
      return;
    }
    if (!dataStore) {
      history.replace(
        paymentId
          ? `/${CURRENT_LANG_PREFIX}/checkout/payment?payment_id=${paymentId}&paymentStatus=${paymentStatus}`
          : `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${responseMessage}&paymentStatus=${paymentStatus}`
      );
      return;
    }
    const result = await axios.post(QUOTE_REPLICA_HO(), reqBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });
    const { data: { quoteId } = {} } = result;
    if (quoteId) {
      await setCurrentQuoteId(quoteId);
    }
    cleverTapProcess({
      eventName: 'order_failed',
      cleverTapData: {
        order_id: orderId,
        country: COUNTRY
      }
    });
    if (status === 'cancel') {
      history.replace(
        `/${CURRENT_LANG_PREFIX}/checkout/payment?paymentHoldCall=1`
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      history.replace(
        `/${CURRENT_LANG_PREFIX}/account/orderview/${orderId}?phfv=1`
      );
    }
  } catch (e) {
    logError(e);
    history.replace(
      `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${responseMessage}&paymentStatus=${paymentStatus}`
    );
  }
}
export async function setWhatsAppOpt({ requestFlag }) {
  let result;
  let jwt = getJWT();
  if (!jwt) {
    try {
      await getJwtFromEmail({ email: 'guest@stylishop.com' });
    } catch (e) {
      logError(e);
    }
    jwt = getJWT();
  }
  try {
    result = axios.post(
      API_WHATSAPP_OPT(),
      {
        requestFlag,
        customerId: getCustomerId()
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: jwt,
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (e) {
    logError(e);
  }
  return result;
}

export async function changePaymentMethod(
  {
    quote = {},
    paymentCode,
    selectWallet = false,
    cardBin,
    bankOffersData,
    isPartialLoading = false,
    donationAmount,
    isFirstCall = false,
    isPaymentHoldCall = false
  },
  { shippingDispatch }
) {
  const dispatch = shippingDispatch;

  dispatch({
    type: ACTION_GET_TOTALS_LOADING,
    data: { isTotalPartialLoading: isPartialLoading }
  });
  const config = await getStorage('config');

  let jwt = getJWT();
  if (!jwt) {
    try {
      await getJwtFromEmail({ email: `guest@stylishop.com` });
    } catch (e) {
      logError(e);
    }
    jwt = getJWT();
  }

  const storeId = getStoreId();
  const customerId = getCustomerId();
  const isBankOffer =
    bankOffersData &&
    bankOffersData.filter(
      offer => offer.cardbinList.indexOf(Number(cardBin)) > -1
    );
  let reqBody = {
    storeId,
    paymentMethod: paymentCode,
    applyStoreCredit: selectWallet,
    cardBin: isBankOffer && isBankOffer.length ? cardBin : '',
    isFirstCall
  };

  if (donationAmount !== undefined) {
    reqBody.donationAmount = donationAmount;
  }
  if (isPaymentHoldCall && quote.quoteId) {
    reqBody = {
      ...reqBody,
      customerId,
      quoteId: quote.quoteId,
      retryPayment: true,
      donationAmount: 0.0,
      applyStoreCredit: false
    };
  } else if (customerId) {
    reqBody.customerId = customerId;
  } else {
    reqBody.quoteId = quote.quoteId;
  }

  try {
    const totalsResponse = await axios.post(GET_TOTALS_API(), reqBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: jwt,
        'Content-Type': 'application/json'
      }
    });

    const { statusCode, response } = totalsResponse && totalsResponse.data;

    if (statusCode === '200') {
      const shouldAddStyliCredit = !!(Number(response?.paidStyliCredit) > 0);
      if (shouldAddStyliCredit && isPaymentHoldCall) {
        setStorage('styliCredithold', true);
      }
      await setStorage(
        'orderCodEligible',
        totalsResponse?.data?.response?.paymentRestriction?.codEnabled
      );
      if (donationAmount === undefined)
        logEvent({
          eventName: 'change_payment',
          data: {
            quantity: quote.itemsQty,
            grandTotal: quote.grandTotal,
            couponCodeApplied: quote.couponCodeApplied,
            paymentMethod: paymentCode
          },
          screenName: 'Checkout_Payment'
        });
      dispatch({
        type: ACTION_GET_TOTALS_SUCCESS,
        data: {
          quote: { ...quote, ...response },
          config
        }
      });
    } else {
      dispatch({ type: ACTION_GET_TOTALS_FAILED });
    }
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_TOTALS_FAILED });
  }
}
