import axios from 'utils/axios';
// import firebase from '@firebase/app';
// import remoteConfig from '@firebase/remote-config';
import isEmpty from 'lodash.isempty';
import { differenceInMinutes } from 'date-fns';

import {
  path,
  getJWT,
  deleteCookie,
  getStoreId,
  getCookieValue,
  setCookie,
  setSentryCustomer,
  // initializeFirebase,
  arrayToObj,
  getGenderPreference
} from 'utils';
import { logEvent, setUser, timingVisualComplete } from 'utils/analytics';

import {
  getCustomerId,
  getXHeaderToken,
  getAgeGroupId,
  getJwtFromEmail
} from 'utils/user';
import { setStorage, getStorage, logoutStorage } from 'utils/storage';
import logError from 'utils/logError';

import log from 'utils/log';
import {
  LOGIN_VALIDATE,
  LOGIN,
  API_GET_ORDER_LISTING,
  API_GET_COUPON_LISTING,
  API_GET_RETURN_ORDER_LISTING,
  API_GET_CREDIT_HISTORY,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PROFILE_UPDATE_API,
  MIGRATE_QUOTE_API,
  GET_ORDER_DETAILS,
  GET_RATE_DETAILS,
  SAVE_RATE_DETAILS,
  API_CANCEL_ORDER,
  API_GET_UNPAID_ORDER_LISTING,
  API_CANCEL_ORDER_INIT,
  API_RETURN_ORDER,
  API_GET_ORDER_COUNT,
  GET_INIT_RMA,
  API_CANCEL_RMA,
  GET_STORE_CREDIT,
  GET_PROFILE,
  GET_CONFIG,
  GET_CITY_DETAILS,
  GET_AUTO_COMPLETE,
  CREATE_PASSWORD,
  GET_SEND_OTP,
  GET_VERIFY_OTP,
  GET_SEND_DELETE_OTP,
  GET_TABBY_ORDER_DETAILS,
  VERIFY_RECAPTCHA,
  GET_WISHLIST,
  API_GET_RETURN_COUNT,
  GET_BANK_NAMES_AND_SWIFT_CODES,
  CREATE_BANK_TRANSFER,
  DELETE_REQUEST,
  GET_PAYABLE_ORDERS,
  DELETE_ELIGIBLE_CUSTOMER_API
} from 'constants/api';

import {
  getUpdateQuoteDetails,
  setCurrentQuoteId,
  migrateMetaData
} from 'containers/Checkout/actions';
import { setDeliverySlaArea, setDeliverySlaCityId } from 'utils/address';

import {
  CURRENT_LANG_PREFIX,
  JWT_COOKIE_NAME,
  REFRESH_TOKEN,
  UID_COOKIE_NAME,
  PHONE_CODE,
  COUNTRY,
  WEBSITE_ID,
  DEFAULT_SHIPPING_CHARGE,
  DEFAULT_MIN_BAG_VALUE_FREE_SHIP,
  ORDER_LIST_PAGE_SIZE,
  RETURN_ORDER_LIST_PAGE_SIZE,
  COUNTRY_KSA,
  RC_CACHE_TIME
  // STATUS_EXCLUDED_FOR_COUNT
} from '../../constants';
import { translate, UNKNOWN_ERROR } from '../../constants/language';

export const ACTION_GET_STORE_CREDIT = 'account/GET_STORE_CREDIT';
export const ACTION_DELETE_REASON_FAILURE = 'account/DELETE_REASON_FAILURE';
export const ACTION_GET_PROFILE_PAGE = 'account/GET_PROFILE';
export const ACTION_GET_PROFILE_FAILED = 'account/GET_PROFILE_FAILED';
export const ACTION_LOGOUT = 'account/LOGOUT';
export const ACTION_VALIDATE_LOGIN_SUCCESS = 'account/VALIDATE_LOGIN_SUCCESS';
export const ACTION_VALIDATE_LOGIN_FALIURE = 'account/VALIDATE_LOGIN_FALIURE';
export const ACTION_RESET_VALIDATED_USER = 'account/RESET_VALIDATED_USER';
export const ACTION_RESET_REGISTRATION = 'account/RESET_REGISTRATION';
export const ACTION_REGISTRATION_SUCCESS = 'account/REGISTRATION_SUCCESS';
export const ACTION_REGISTRATION_FALIURE = 'account/REGISTRATION_FALIURE';
export const ACTION_REGISTRATION_LOADING = 'account/REGISTRATION_LOADING';
export const ACTION_LOGIN_LOADING = 'account/LOGIN_LOADING';
export const ACTION_LOGIN_SUCCESS = 'account/LOGIN_SUCCESS';
export const ACTION_LOGIN_REDIRECT = 'account/LOGIN_REDIRECT';
export const ACTION_LOGIN_FALIURE = 'account/LOGIN_FALIURE';
export const ACTION_VALIDATE_LOGIN_LOADING = 'account/LOGIN_LOADINF';
export const ACTION_CONFIG_VALUES = 'account/CONFIG_VALUES';
export const ACTION_SITE_CONFIG_VALUES = 'account/ACTION_SITE_CONFIG_VALUES';
export const ACTION_FORGOT_PASSWORD_LOADING = 'account/FORGOT_PASSWORD_LOADING';
export const ACTION_FORGOT_PASSWORD_SUCCESS = 'account/FORGOT_PASSWORD_SUCCESS';
export const ACTION_RESET_PASSWORD_LOADING = 'account/RESET_PASSWORD_LOADING';
export const ACTION_RESET_PASSWORD_SUCCESS = 'account/RESET_PASSWORD_SUCCESS';
export const ACTION_RESET_PASSWORD_FALIURE = 'account/RESET_PASSWORD_FALIURE';
export const ACTION_CREATE_PASSWORD_LOADING = 'account/CREATE_PASSWORD_LOADING';
export const ACTION_CREATE_PASSWORD_SUCCESS = 'account/CREATE_PASSWORD_SUCCESS';
export const ACTION_CREATE_PASSWORD_FALIURE = 'account/CREATE_PASSWORD_FALIURE';
export const ACTION_PROFILE_UPDATE_FAILURE = 'account/PROFILE_UPDATE_FAILURE';
export const ACTION_PROFILE_UPDATE_SUCCESS = 'account/PROFILE_UPDATE_SUCCESS';
export const ACTION_PROFILE_UPDATE_LOADING = 'account/PROFILE_UPDATE_LOADING';
export const ACTION_SHOW_TOAST = 'account/SHOW_TOAST';
export const ACTION_HIDE_TOAST = 'account/HIDE_TOAST';
export const ACTION_VERIFY_PHONE_START = 'account/VERIFY_PHONE_START';
export const ACTION_VERIFY_PHONE_FINISHED = 'account/VERIFY_PHONE_FINISHED';
export const ACTION_VERIFY_PHONE_LOADING = 'account/VERIFY_PHONE_LOADING';
export const ACTION_ORDER_LIST_LOADING = 'account/ORDER_LIST_LOADING';
export const ACTION_ORDER_LIST_FETCHED = 'account/ORDER_LIST_FETCHED';
export const ACTION_RETURN_ORDER_LIST_LOADING =
  'account/RETURN_ORDER_LIST_LOADING';
export const ACTION_RETURN_ORDER_LIST_FETCHED =
  'account/RETURN_ORDER_LIST_FETCHED';
export const ACTION_ORDER_LIST_ERROR = 'account/ORDER_LIST_ERROR';
export const ACTION_RETURN_ORDER_LIST_ERROR = 'account/RETURN_ORDER_LIST_ERROR';
export const ACTION_ORDER_CANCEL_LOADING = 'account/ORDER_CANCEL_LOADING';
export const ACTION_ORDER_CANCELED = 'account/ORDER_CANCELED';
export const ACTION_ORDER_DETAILS_FETCHED = 'account/ORDER_DETAILS_FETCHED';
export const ACTION_ORDER_DETAILS_ERROR = 'account/ORDER_DETAILS_ERROR';
export const ACTION_ORDER_DETAILS_LOADING = 'account/ORDER_DETAILS_LOADING';
export const ACTION_PAYABLE_ORDERS_LOADING = 'account/PAYABLE_ORDERS_LOADING';
export const ACTION_PAYABLE_ORDERS_FETCHED = 'account/PAYABLE_ORDERS_FETCHED';
export const ACTION_PAYABLE_ORDERS_ERROR = 'account/PAYABLE_ORDERS_ERROR';
export const ACTION_RATE_DETAILS_LOADING = 'account/RATE_DETAILS_LOADING';
export const ACTION_SAVE_RATE_DETAILS_LOADING =
  'account/SAVE_RATE_DETAILS_LOADING';
export const ACTION_RATE_DETAILS_SAVED = 'account/RATE_DETAILS_SAVED';
export const ACTION_SAVE_RATE_DETAILS_ERROR = 'account/SAVE_RATE_DETAILS_ERROR';
export const ACTION_RATE_DETAILS_FETCHED = 'account/RATE_DETAILS_FETCHED';
export const ACTION_RATE_DETAILS_ERROR = 'account/RATE_DETAILS_ERROR';
export const ACTION_INIT_RMA_FETCHED = 'account/INIT_RMA_FETCHED';
export const ACTION_INIT_RMA_LOADING = 'account/INIT_RMA_LOADING';
export const ACTION_RESET_ACCOUNT_STATE = 'account/RESET_ACCOUNT_STATE';
export const ACTION_COUPON_LIST_LOADING = 'account/COUPON_LIST_LOADING';
export const ACTION_COUPON_LIST_FETCHED = 'account/COUPON_LIST_FETCHED';
export const ACTION_ORDER_INIT_LOADING = 'account/ORDER_INIT_LOADING';
export const ACTION_ORDER_INIT_LOADED = 'account/ORDER_INIT_LOADED';
export const ACTION_ORDER_COUNT_LOADING = 'account/ORDER_COUNT_LOADING';
export const ACTION_ORDER_COUNT_LOADED = 'account/ORDER_COUNT_LOADED';

export const ACTION_RETURN_COUNT_LOADING = 'account/RETURN_COUNT_LOADING';
export const ACTION_RETURN_COUNT_LOADED = 'account/RETURN_COUNT_LOADED';

export const ACTION_ARCHIVE_RETURN_COUNT_LOADING =
  'account/ARCHIVE_RETURN_COUNT_LOADING';
export const ACTION_ARCHIVE_RETURN_COUNT_LOADED =
  'account/ARCHIVE_RETURN_COUNT_LOADED';

export const ACTION_ARCHIVE_ORDER_COUNT_LOADING =
  'account/ARCHIVE_ORDER_COUNT_LOADING';
export const ACTION_ARCHIVE_ORDER_COUNT_LOADED =
  'account/ARCHIVE_ORDER_COUNT_LOADED';

export const ACTION_CREDIT_HISTORY_FETCHED = 'account/CREDIT_HISTORY_FETCHED';
export const ACTION_CREDIT_HISTORY_LOADING =
  'account/ACTION_CREDIT_HISTORY_LOADING';
export const ACTION_RETURN_ITEM_LOADING = 'account/RETURN_INIT_LOADING';
export const ACTION_RETURN_ITEM_LOADED = 'account/RETURN_INIT_LOADED';
export const ACTION_CANCEL_RMA_LOADING = 'account/CANCEL_RMA_LOADING';
export const ACTION_CANCEL_RMA_LOADED = 'account/CANCEL_RMA_LOADED';
export const ACTION_RETURN_DATA_UPDATE = 'account/RETURN_DATA_UPDATE';
export const ACTION_RETURN_ITEM_ERROR = 'account/RETURN_ITEM_ERROR';
export const ACTION_GET_INPAGE_BANNERS = 'account/GET_INPAGE_BANNERS';
export const ACTION_DEFAULT_ADDRESS_LOADING = 'account/DEFAULT_ADDRESS_LOADING';
export const ACTION_DEFAULT_ADDRESS_FETCHED = 'account/DEFAULT_ADDRESS_FETCHED';
export const ACTION_DEFAULT_ADDRESS_ERROR = 'account/DEFAULT_ADDRESS_ERROR';
export const ACTION_CITY_DETAILS_LOADING = 'account/CITY_DETAILS_LOADING';
export const ACTION_CITY_DETAILS_FETCHED = 'account/CITY_DETAILS_FETCHED';
export const ACTION_CITY_DETAILS_ERROR = 'account/CITY_DETAILS_ERROR';
export const ACTION_CITY_DETAILS_ERROR_RESET =
  'account/CITY_DETAILS_ERROR_RESET';
export const ACTION_DEFAULT_CITY_DETAILS_LOADING =
  'account/DEFAULT_CITY_DETAILS_LOADING';
export const ACTION_DEFAULT_CITY_DETAILS_FETCHED =
  'account/DEFAULT_CITY_DETAILS_FETCHED';
export const ACTION_DEFAULT_CITY_DETAILS_ERROR =
  'account/DEFAULT_CITY_DETAILS_ERROR';

export const ACTION_SEARCH_DATA_LOADING = 'account/SEARCH_DATA_LOADING';
export const ACTION_SEARCH_DATA_FETCHED = 'account/SEARCH_DATA_FETCHED';
export const ACTION_SEARCH_DATA_ERROR = 'account/SEARCH_DATA_ERROR';
export const ACTION_POPULAR_SEARCH_DATA_LOADING =
  'account/POPULAR_SEARCH_DATA_LOADING';
export const ACTION_POPULAR_SEARCH_DATA_FETCHED =
  'account/POPULAR_SEARCH_DATA_FETCHED';
export const ACTION_POPULAR_SEARCH_DATA_ERROR =
  'account/POPULAR_SEARCH_DATA_ERROR';
export const ACTION_GET_DESKTOP_CATEGORIES2 = 'home/GET_CATEGORY_PAGE';
export const ACTION_GET_BANNER_IMAGE_CARDS = 'account/GET_BANNER_IMAGE_CARDS';
export const ACTION_RECAPTCHA_VERIFIED = 'account/RECAPTCHA_VERIFIED';
export const ACTION_RECAPTCHA_ERROR = 'account/RECAPTCHA_ERROR';
export const ACTION_GET_WISHLIST_COUNT = 'account/GET_WISHLIST_COUNT';
export const ACTION_GET_WISHLIST_COUNT_FAILURE =
  'account/GET_WISHLIST_COUNT_FAILURE';
export const ACTION_GET_WISHLIST_COUNT_LOADING =
  'account/GET_WISHLIST_COUNT_LOADING';
export const ACTION_BANK_NAMES_LOADING = 'account/BANK_NAMES_LOADING';
export const ACTION_BANK_NAMES_FETCHED = 'account/BANK_NAMES_FETCHED';
export const ACTION_BANK_NAMES_ERROR = 'account/BANK_NAMES_ERROR';
export const ACTION_CREATE_BANK_TRANSFER_LOADING =
  'account/CREATE_BANK_TRANSFER_LOADING';
export const ACTION_BANK_TRANSFER_CREATED = 'account/BANK_TRANSFER_CREATED';
export const ACTION_CREATE_BANK_TRANSFER_ERROR =
  'account/CREATE_BANK_TRANSFER_ERROR';

export const showToast = (
  { type = 'success', content = '', timeOut = 2000, ctaAction },
  dispatch
) => {
  if (content) {
    dispatch({
      type: ACTION_SHOW_TOAST,
      data: { content, type, timeOut, ctaAction }
    });

    setTimeout(() => {
      dispatch({
        type: ACTION_HIDE_TOAST
      });
    }, timeOut);
  }
};

export const resetState = async dispatch => {
  dispatch({
    type: ACTION_RESET_ACCOUNT_STATE
  });
};

export const verifyPhone = async (
  { phone, verify = false, otp = '' },
  dispatch
) => {
  const config = await getStorage('config');

  dispatch({
    type: ACTION_VERIFY_PHONE_LOADING
  });
  try {
    const response = await axios.post(
      `${config.cloudFunctionUrl}/verifyOtp`,
      { to: phone, verify, otp, ln: CURRENT_LANG_PREFIX },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data) {
      dispatch({
        type: ACTION_VERIFY_PHONE_START,
        data: { isValid: response.data.isValid }
      });
      return { isValid: response.data.isValid };
    }
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_VERIFY_PHONE_FINISHED, data: { success: false } });

    showToast({ type: 'error', content: translate(UNKNOWN_ERROR) }, dispatch);
  }
  return { isValid: false };
};

export const closeVerifyPhone = async (
  { success = false, isRegistration },
  dispatch
) => {
  dispatch({
    type: ACTION_VERIFY_PHONE_FINISHED,
    data: { success, isRegistration }
  });
};

export const sendOTP = async (
  {
    phone,
    email,
    isRegistration = false,
    isPhonePrompt,
    envokedFrom,
    isHold = false
  },
  dispatch
) => {
  const storeId = getStoreId();
  const messageCode = 'default';
  const mobileNo = phone;
  if (!isHold) {
    dispatch({
      type: ACTION_VERIFY_PHONE_LOADING,
      data: { isRegistration, envokedFrom }
    });
  }

  try {
    if (mobileNo) {
      const result = await axios.post(
        GET_SEND_OTP(),
        { mobileNo, email, storeId, messageCode },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({
        type: ACTION_VERIFY_PHONE_START,
        data: {
          phone: isPhonePrompt ? phone : '',
          profilePhone: envokedFrom === 'profile' ? phone : ''
        }
      });
      return {
        isOTPSent: result.data.response.value
      };
    }
  } catch (e) {
    logError(e);
    dispatch({
      type: ACTION_VERIFY_PHONE_FINISHED,
      data: { success: false, isRegistration }
    });

    showToast({ type: 'error', content: translate(UNKNOWN_ERROR) }, dispatch);
  }
  return { isValid: false };
};
export const deleteEligibleCustomer = async dispatch => {
  try {
    const customerId = getCustomerId();
    const storeId = getStoreId();
    const response = await axios.post(
      DELETE_ELIGIBLE_CUSTOMER_API(),
      { customerId, storeId },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT()
        }
      }
    );

    return {
      data: response.data
    };
  } catch (error) {
    logError(error);
    dispatch({
      type: ACTION_DELETE_REASON_FAILURE,
      error
    });
    return error;
  }
};
export const deleteAccountOtp = async dispatch => {
  try {
    const customerId = getCustomerId();
    const storeId = getStoreId();
    const response = await axios.post(
      GET_SEND_DELETE_OTP(),
      { customerId, storeId },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT()
        }
      }
    );

    return {
      data: response.data
    };
  } catch (error) {
    logError(error);
    dispatch({
      type: ACTION_DELETE_REASON_FAILURE,
      error
    });
    throw error;
  }
};

export const verifyOTP = async (
  {
    phone,
    otp,
    type = '',
    isRegistration = false,
    registerData = {},
    envokedFrom,
    email,
    isUserConsentProvided
  },
  dispatch
) => {
  const mobileNo = phone;
  const customerInfo = {};
  if (isRegistration) {
    customerInfo.customer = registerData;
    customerInfo.password = registerData.password;
  }
  dispatch({
    type: ACTION_VERIFY_PHONE_LOADING,
    data: { isRegistration, envokedFrom }
  });
  let result = {};
  try {
    if(isUserConsentProvided){
      customerInfo.isUserConsentProvided = isUserConsentProvided 
    }
    if (mobileNo) {
      result = await axios.post(
        GET_VERIFY_OTP(),
        { mobileNo, otp, type, customerInfo, email },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({
        type: ACTION_VERIFY_PHONE_START,
        data: {
          profilePhone: envokedFrom === 'profile' ? mobileNo : '',
          isValid: result.data.status,
          isRegistration,
          otp,
          ...result.data
        }
      });
      return {
        isValid: result.data.status,
        isRegistration,
        customerResult: result
      };
    }
  } catch (e) {
    logError(e);
    dispatch({
      type: ACTION_VERIFY_PHONE_FINISHED,
      data: { success: false, isRegistration }
    });

    showToast({ type: 'error', content: translate(UNKNOWN_ERROR) }, dispatch);
  }
  return { isValid: false };
};

export const getStoreCredit = async (option, dispatch) => {
  try {
    const storeId = getStoreId();
    const customerId = getCustomerId();
    if (customerId) {
      const result = await axios.post(
        GET_STORE_CREDIT({ customerId }),
        { customerId, storeId },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({
        type: ACTION_GET_STORE_CREDIT,
        data: path(['data', 'response'], result) || {}
      });
    }
  } catch (e) {
    logError(e);
  }
};
export async function loginToMagento() {
  const data = await getStorage('profile');
  if (data) {
    const { email } = data.customer;

    const jwt = getJWT().replace('KEY ', '');
    try {
      await axios.get(
        `/${CURRENT_LANG_PREFIX}/customerlogin/login/save/email/${encodeURIComponent(
          email
        )}/jwt/${encodeURIComponent(jwt || '')}` // .replace(/-/g, '%E2%80%93')}`
      );
    } catch (e) {
      logError(e);
    }
  }
}
export async function logout(options, dispatch, shippingDispatch) {
  dispatch({ type: ACTION_LOGOUT });
  deleteCookie(JWT_COOKIE_NAME);
  deleteCookie(UID_COOKIE_NAME);
  deleteCookie('r_email');
  await getJwtFromEmail({ email: 'guest@stylishop.com' });
  await logoutStorage();
  window.location.href = window.location.origin;

  if (false) {
    await getUpdateQuoteDetails({}, shippingDispatch);
    axios.get(`/${CURRENT_LANG_PREFIX}/customer/account/logout/`);
  }
}

export async function getOrderCount(dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (customerId) {
    dispatch({ type: ACTION_ORDER_COUNT_LOADING });

    try {
      const requestObj = {
        customerId,
        storeId
      };

      const result = await axios.post(
        API_GET_ORDER_COUNT(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      const { response } = result.data || {};
      dispatch({ type: ACTION_ORDER_COUNT_LOADED, data: response });
    } catch (e) {
      logError(e);
    }
  }
}

export async function getArchiveOrderCount(dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (customerId) {
    dispatch({ type: ACTION_ARCHIVE_ORDER_COUNT_LOADING });

    try {
      const requestObj = {
        customerId,
        storeId,
        useArchive: true
      };

      const result = await axios.post(
        API_GET_ORDER_COUNT(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      const { response } = result.data || {};
      dispatch({ type: ACTION_ARCHIVE_ORDER_COUNT_LOADED, data: response });
    } catch (e) {
      logError(e);
    }
  }
}

export async function getReturnCount(dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (customerId) {
    dispatch({ type: ACTION_RETURN_COUNT_LOADING });

    try {
      const requestObj = {
        customerId,
        storeId
      };

      const result = await axios.post(
        API_GET_RETURN_COUNT(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );

      const { totalCount } = result.data || {};
      dispatch({ type: ACTION_RETURN_COUNT_LOADED, data: totalCount });
    } catch (e) {
      logError(e);
    }
  }
}

export async function getReturnArchiveCount(dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (customerId) {
    dispatch({ type: ACTION_ARCHIVE_RETURN_COUNT_LOADING });

    try {
      const requestObj = {
        customerId,
        storeId,
        useArchive: true
      };

      const result = await axios.post(
        API_GET_RETURN_COUNT(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );

      const { totalCount } = result.data || {};
      dispatch({ type: ACTION_ARCHIVE_RETURN_COUNT_LOADED, data: totalCount });
    } catch (e) {
      logError(e);
    }
  }
}

export async function getOrderList(options, dispatch) {
  dispatch({ type: ACTION_ORDER_LIST_LOADING });
  const customerId = getCustomerId();
  const storeId = getStoreId();
  try {
    if (customerId) {
      const requestObj = {
        customerId,
        storeId,
        offSet: options.offSet,
        pageSize: ORDER_LIST_PAGE_SIZE,
        useArchive: options.isArchive
      };

      const result = await axios.post(
        options.isUnpaid
          ? API_GET_UNPAID_ORDER_LISTING()
          : API_GET_ORDER_LISTING(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      const { statusCode, response } = result.data || {};

      if (statusCode === '200') {
        dispatch({
          type: ACTION_ORDER_LIST_FETCHED,
          data: {
            orderPageOffset: options.offSet,
            orderData: response || [],
            orderListingComplete: (response || []).length < ORDER_LIST_PAGE_SIZE
          }
        });
        await setStorage('isArchival', !!options.isArchive);
      } else {
        dispatch({ type: ACTION_ORDER_LIST_ERROR });
      }
    }
  } catch (e) {
    dispatch({ type: ACTION_ORDER_LIST_ERROR });

    logError(e.message);
  }
}

export async function getReturnOrderList(options, dispatch) {
  dispatch({ type: ACTION_RETURN_ORDER_LIST_LOADING });
  const customerId = getCustomerId();
  const storeId = getStoreId();
  try {
    if (customerId) {
      const requestObj = {
        customerId,
        storeId,
        offSet: options.offSet,
        pageSize: RETURN_ORDER_LIST_PAGE_SIZE,
        useArchive: options.isArchive
      };

      const result = await axios.post(
        // const result = await axios.get(
        API_GET_RETURN_ORDER_LISTING(),
        {
          ...requestObj
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      const { statusCode, response } = result.data || {};

      const responseArr = Object.keys(response)
        .sort((a, b) => {
          return b - a;
        })
        .map(key => {
          return [key, response[key]];
        });

      if (statusCode === '200') {
        dispatch({
          type: ACTION_RETURN_ORDER_LIST_FETCHED,
          data: {
            returnOrderPageOffset: options.offSet,
            returnOrderData: responseArr,
            returnOrderListingComplete:
              responseArr.length < RETURN_ORDER_LIST_PAGE_SIZE
          }
        });
        await setStorage('isReturnArchival', !!options.isArchive);
      } else {
        dispatch({ type: ACTION_RETURN_ORDER_LIST_ERROR });
      }
    }
  } catch (e) {
    dispatch({ type: ACTION_RETURN_ORDER_LIST_ERROR });

    logError(e.message);
  }
}

export async function getCreditHistory(options, dispatch) {
  const storeId = getStoreId();
  const customerId = getCustomerId();
  dispatch({ type: ACTION_CREDIT_HISTORY_LOADING });
  try {
    const result = await axios.post(
      API_GET_CREDIT_HISTORY(),
      { storeId, customerId },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response, returnableAmount } = result.data || {};
    const respObj = { response, returnableAmount };
    if (statusCode === '200') {
      dispatch({
        type: ACTION_CREDIT_HISTORY_FETCHED,
        data: respObj
      });
    }
  } catch (e) {
    logError(e.message);
  }
}

export async function getCouponList(options, dispatch) {
  const storeId = getStoreId();
  const customerId = getCustomerId();
  let customerEmail = '';

  const user = await getStorage('profile');
  if (user) {
    const { email } = user.customer;
    customerEmail = email;
  }
  const reqBody =
    customerId && customerId !== '' && customerEmail && customerEmail !== ''
      ? { storeId, customerId, customerEmail }
      : { storeId };
  dispatch({ type: ACTION_COUPON_LIST_LOADING });
  try {
    const result = await axios.post(
      API_GET_COUPON_LISTING(),
      { ...reqBody },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response } = result.data || {};

    if (statusCode === '200') {
      dispatch({
        type: ACTION_COUPON_LIST_FETCHED,
        data: { couponsCount: response.length, couponsData: response }
      });
    }
  } catch (e) {
    logError(e.message);
  }
}

export const getProfile = async (option = {}, dispatch, shippingDispatch) => {
  try {
    const customerId = getCustomerId();
    if (customerId) {
      let data = {};

      data = await getStorage('profile');
      if (!isEmpty(data)) {
        dispatch({
          type: ACTION_GET_PROFILE_PAGE,
          data
        });
        try {
          setUser({
            // email: data.customer.email,
            customerId: data.customer.customerId
          });
          setSentryCustomer({
            email: data.customer.email,
            id: data.customer.customerId
          });
          if (window.appboy && window.appboy.setUser) {
            // window.appboy.changeUser(data.customer.customerId);
            // window.appboy.setEmail(data.customer.email);
            // window.appboy.setFirstName(data.customer.firstName);
            // if (data.customer.lastName) {
            //   window.appboy.setLastName(data.customer.lastName);
            // }

            //   // brazePlugin.setEmail(userInfo["email"]);
            //   // brazePlugin.setFirstName(userInfo["firstName"]);
            //   // brazePlugin.setLastName(userInfo["lastName"]);
            //   // brazePlugin.setPhoneNumber(userInfo["mobile"]);

            window.appboy.setUser({
              email: data.customer.email,
              customerId: data.customer.customerId,
              firstName: data.customer.firstName,
              lastName: data.customer.lastName
            });
          }
        } catch (e) {
          logError(e);
        }
      }

      if (isEmpty(data) || !option.cacheOnly) {
        let result = {};
        try {
          result = await axios.post(
            GET_PROFILE({ customerId }),
            { customerId, storeId: getStoreId() },
            {
              headers: {
                'X-Header-Token': getXHeaderToken(),
                Token: getJWT(),
                'Content-Type': 'application/json'
              }
            }
          );
        } catch (e) {
          logError(e);
        }

        data = path(['data', 'response'], result);
        const statusCode = path(['data', 'statusCode'], result);

        if (statusCode === '200' || statusCode === 200) {
          await setStorage('profile', data);
          try {
            setSentryCustomer({
              email: data.customer.email,
              id: data.customer.customerId
            });
            if (window.appboy && window.appboy.setUser) {
              window.appboy.setUser({
                email: data.customer.email,
                customerId: data.customer.customerId,
                firstName: data.customer.firstName,
                lastName: data.customer.lastName
              });
            }
          } catch (e) {
            logError(e);
          }
        }

        if (data && data.customer) {
          // if (option.inAccountPage) loginToMagento();
          dispatch({
            type: ACTION_GET_PROFILE_PAGE,
            data
          });
        } else {
          logout({}, dispatch, shippingDispatch);
        }
      } else {
        logout({}, dispatch, shippingDispatch);
      }
    } else {
      dispatch({ type: ACTION_GET_PROFILE_FAILED });
    }
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_PROFILE_FAILED });
  }
};

export async function getLoginValidate(
  { useridentifier, loginType, initiatedFrom = '' },
  dispatch
) {
  let trimmedUserIdentifier = useridentifier;
  let finalUserIndentifier = '';
  let exists = false;
  if (useridentifier.substr(0, 1) === '0') {
    trimmedUserIdentifier = useridentifier.substr(1, useridentifier.length);
  }

  try {
    if (initiatedFrom === 'profile') {
      dispatch({
        type: ACTION_PROFILE_UPDATE_LOADING
      });
    } else {
      dispatch({
        type: ACTION_VALIDATE_LOGIN_LOADING
      });
    }
    const storeId = await getStoreId();

    finalUserIndentifier = useridentifier;

    if (loginType === 'MOBILE_LOGIN') {
      if (!initiatedFrom || initiatedFrom === 'phonePrompt') {
        finalUserIndentifier = Number(trimmedUserIdentifier);
        const isOTPTest = getCookieValue('test_otp') === '1';
        finalUserIndentifier = `${
          isOTPTest ? PHONE_CODE.AE : PHONE_CODE[COUNTRY.toUpperCase()]
        } ${finalUserIndentifier}`;
      }
    }

    const result = await axios.post(
      LOGIN_VALIDATE(),
      {
        loginType,
        storeId,
        useridentifier: finalUserIndentifier
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], result);
    const { statusCode, response } = data || {};
    data.loginType = loginType;
    if (
      ['profile', 'phonePrompt', 'orderFlow', 'addAddress'].includes(
        initiatedFrom
      ) &&
      response
    ) {
      if (response.exists) {
        exists = response.exists;
        if (initiatedFrom === 'profile') {
          dispatch({
            type: ACTION_PROFILE_UPDATE_FAILURE
          });
        }
        if (initiatedFrom === 'addAddress') {
          showToast(
            {
              type: 'error',
              content: translate(
                "Sorry, this email is already registered. Let's try signing in?"
              )
            },
            dispatch
          );
        }
        if (initiatedFrom === 'phonePrompt' && loginType === 'MOBILE_LOGIN') {
          showToast(
            {
              type: 'error',
              content: translate(
                'Sorry, this mobile is already registered with us.'
              )
            },
            dispatch
          );
        }
        dispatch({
          type: ACTION_VALIDATE_LOGIN_SUCCESS,
          data
        });

        return { exists, finalUserIndentifier };
      }
    }
    if (!initiatedFrom) {
      if (statusCode === '200' && response.exists) {
        dispatch({
          type: ACTION_VALIDATE_LOGIN_SUCCESS,
          data
        });
        const eventData = {
          event_category: 'login_signup',
          event_label: 'success',
          method: loginType === 'MOBILE_LOGIN' ? 'mobile' : 'email',
          value: trimmedUserIdentifier
        };
        if (loginType === 'MOBILE_LOGIN') {
          // verifyPhone({ phone: finalUserIndentifier }, dispatch);
          sendOTP({ phone: finalUserIndentifier }, dispatch);
        }

        logEvent({
          eventName: 'user_input_manual',
          data: eventData,
          screenName: 'Login_Auth_with_OTP'
        });
      } else if (statusCode === '201') {
        dispatch({
          type: ACTION_VALIDATE_LOGIN_FALIURE,
          data: {
            useridentifier: trimmedUserIdentifier,
            loginType,
            statusCode
          }
        });
      } else if (statusCode === '300') {
        showToast(
          {
            type: 'error',
            content: translate('Invalid email. Can you check the format?')
          },
          dispatch
        );
      } else {
        showToast({ type: 'error', content: UNKNOWN_ERROR }, dispatch);
        dispatch({
          type: ACTION_VALIDATE_LOGIN_FALIURE,
          data: {
            useridentifier: trimmedUserIdentifier,
            loginType,
            statusCode: 404,
            statusMsg: UNKNOWN_ERROR
          }
        });
      }
    }
  } catch (e) {
    logError(e);
    showToast({ type: 'error', content: UNKNOWN_ERROR }, dispatch);
    dispatch({
      type: ACTION_VALIDATE_LOGIN_FALIURE,
      data: {
        useridentifier: trimmedUserIdentifier,
        loginType,
        statusCode: 404,
        statusMsg: UNKNOWN_ERROR
      }
    });
  }
  return { exists, finalUserIndentifier };
}

export async function resetValidate(options, dispatch) {
  dispatch({ type: ACTION_RESET_VALIDATED_USER });
}

export async function resetRegistration(options, dispatch) {
  dispatch({ type: ACTION_RESET_REGISTRATION });
}
function registerFailureEventLog({ reason }) {
  try {
    logEvent({
      eventName: 'sign_up_form_failure',
      data: {
        event_category: 'login_signup',
        reason
      },
      screenName: 'User_Registration'
    });
  } catch (e) {
    logError(e);
  }
}

async function migrateBag({ quoteId }, dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (quoteId) {
    const reqBody = {
      customerId,
      quoteList: [
        {
          quoteId,
          storeId
        }
      ]
    };

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const result = await axios.post(MIGRATE_QUOTE_API(), reqBody, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: jwt,
        'Content-Type': 'application/json'
      }
    });

    const { statusCode, response, reponse } = result.data || {};

    if (statusCode === '200') {
      return (response && response.quoteId) || (reponse && reponse.quoteId);
    }
    showToast({ type: 'error', content: UNKNOWN_ERROR }, dispatch);
  }
  return '';
}

export async function register(
  {
    customerResult,
    firstName,
    lastName,
    countryCode,
    mobile,
    email,
    password,
    quote,
    loginType,
    isotpRegister,
    eventActionFrom = '',
    isUserConsentProvided
  },
  dispatch,
  shippingDispatch
) {
  let isSuccess = false;
  const isOTPTest = getCookieValue('test_otp') === '1';
  const phone = mobile
    ? `${isOTPTest ? PHONE_CODE.AE : countryCode} ${mobile}`
    : '';

  dispatch({
    type: ACTION_REGISTRATION_LOADING
  });
  try {
    let result = {};
    if (!customerResult) {
      const gender = getGenderPreference() === 'women' ? 2 : 1;
      const storeId = getStoreId();
      const customerInfo = {
        customer: {
          firstName,
          lastName,
          email,
          gender,
          storeId,
          websiteId: WEBSITE_ID,
          isUserConsentProvided
        },
        password
      };
      if (phone) {
        customerInfo.customer.phone = phone;
      }

      const ageGroupId = await getAgeGroupId();
      if (ageGroupId) {
        customerInfo.customer.ageGroupId = ageGroupId;
      }

      const payload = {
        customerInfo
      };

      result = await axios.post(REGISTER(), payload, {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      });
    } else {
      result = customerResult;
    }
    const data = path(['data'], result);
    const { statusCode, response, status } = data || {};
    let { statusMsg } = UNKNOWN_ERROR;
    if (statusCode === '200') {
      setCookie(JWT_COOKIE_NAME, path(['customer', 'jwtToken'], response), 365);
      setCookie(
        REFRESH_TOKEN,
        path(['customer', 'refreshToken'], response),
        365
      );
      setCookie(
        UID_COOKIE_NAME,
        path(['customer', 'customerId'], response),
        365
      );
      setCookie('r_email', path(['customer', 'email'], response), 365);

      let label = 'email';
      if (loginType === 'SOCIAL_LOGIN_GOOGLE') {
        label = 'google';
      } else if (loginType === 'SOCIAL_LOGIN_APPLE') {
        label = 'apple';
      } else if (loginType === 'MOBILE_LOGIN') {
        label = 'phone';
      }
      try {
        logEvent({
          eventName: 'CUSTOM_EVENT',
          data: {
            event_category: 'login',
            event_action: `successful_signup_${eventActionFrom}`,
            event_label: label
          },
          screenName: 'User_Registration'
        });
      } catch (error) {
        logError(error);
      }
      dispatch({
        type: ACTION_REGISTRATION_SUCCESS,
        data: response
      });
      isSuccess = true;
      const cData = path(['customer'], response) || {};
      logEvent({
        eventName: 'sign_up_form_success',
        data: {
          event_category: 'login_signup',
          signup_source: 'Manual'
        },
        additionalData: { ...cData, loginType, isotpRegister },
        screenName: 'User_Registration'
      });
      getProfile({}, dispatch, shippingDispatch);
      if (quote.quoteId) {
        const newQuoteId = await migrateBag(
          { quoteId: quote.quoteId },
          dispatch
        );
        if (newQuoteId) {
          await setCurrentQuoteId(newQuoteId);
          await getUpdateQuoteDetails({}, shippingDispatch);
          migrateMetaData({
            guestQuoteId: quote.quoteId,
            existingQuoteId: newQuoteId
          });
        }
      }
    } else if (!status) {
      let reason = '';
      if (statusCode === '201') {
        reason = 'Email Already Exists';
        statusMsg = translate(
          "Sorry, this email is already registered. Let's try signing in?"
        );
      } else if (statusCode === '206') {
        reason = 'Phone Already Exists';

        statusMsg = translate(
          "Sorry, this mobile is already registered. Let's try signing in"
        );
      } else if (statusCode === '300') {
        reason = 'Invalid Email';

        statusMsg = translate('Invalid email. Can you check the format?');
      }
      showToast({ type: 'error', content: statusMsg }, dispatch);

      dispatch({
        type: ACTION_REGISTRATION_FALIURE,
        data: { statusCode, statusMsg }
      });
      registerFailureEventLog({ reason });
    }
  } catch (e) {
    logError(e);
    showToast({ type: 'error', content: UNKNOWN_ERROR }, dispatch);

    dispatch({
      type: ACTION_REGISTRATION_FALIURE,
      data: {
        statusCode: 404,
        statusMsg: UNKNOWN_ERROR
      }
    });
    registerFailureEventLog({ reason: UNKNOWN_ERROR });
  }
  return { isSuccess };
}

function loginFailureEventLog() {
  logEvent({
    eventName: 'login_passwd_failure',
    data: { event_category: 'login_signup' },
    screenName: 'Login_Auth_with_Password'
  });
}

export async function getLogin(
  {
    useridentifier,
    password,
    // isStandaloneLogin = true,
    loginType = 'EMAIL_LOGIN',
    quote = {},
    loginData, // This will get only in case of OTP Login
    token,
    fullName,
    phoneNumber = '',
    fromBag,
    isWithdraw = false,
    eventActionFrom = '',
    isUserConsentProvided
  },
  dispatch,
  shippingDispatch
) {
  dispatch({
    type: ACTION_LOGIN_LOADING
  });
  try {
    log('TRY CATCH LOGIN LOADING', quote);
    let data = {};
    if (!loginData) {
      let loginParams = {
        isStandaloneLogin: false,
        loginType,
        password,
        useridentifier,
        socialLoginDetails: {
          tokenId: token
        },
        phoneNumber,
        fullName,
        isUserConsentProvided
      };
      // DUE to some unknown issue we are removing this commenting this code.
      try {
        const ageGroupId = await getAgeGroupId();
        if (ageGroupId) {
          loginParams = { ...loginParams, ageGroupId };
        }
      } catch (e) {
        logError(e);
      }
      loginParams = { ...loginParams, websiteId: WEBSITE_ID };

      log('LOGIN CALLED', loginParams);
      const result = await axios.post(
        LOGIN(),
        { ...loginParams },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );
      log('LOGIN RESULT', result);
      data = path(['data'], result);
    } else {
      data = loginData;
    }
    const { statusCode, statusMsg, response, status } = data || {};
    const { customer = {} } = response;
    const { customerId = '', deleteRequested } = customer;
    if (deleteRequested && !isWithdraw) {
      dispatch({
        type: ACTION_LOGIN_FALIURE,
        data: {
          isActiveUser: false,
          customerId,
          deleteRequested,
          password,
          loginType,
          token,
          fullName
        }
      });
    } else if (
      statusCode === '201' &&
      statusMsg.indexOf('Invalid Password!') > -1
    ) {
      dispatch({
        type: ACTION_LOGIN_FALIURE,
        data: { invalidPassword: true }
      });
      loginFailureEventLog();
    } else if (
      statusCode === '301' &&
      statusMsg.indexOf('Recaptcha needed!') > -1
    ) {
      dispatch({
        type: ACTION_LOGIN_FALIURE,
        data: { recaptchaNeeded: true }
      });
    } else if (!status) {
      showToast({ type: 'error', content: translate(UNKNOWN_ERROR) }, dispatch);
      dispatch({
        type: ACTION_LOGIN_FALIURE,
        data: { invalidPassword: undefined }
      });
      loginFailureEventLog();
    } else {
      setCookie(JWT_COOKIE_NAME, path(['customer', 'jwtToken'], response), 365);
      setCookie(
        REFRESH_TOKEN,
        path(['customer', 'refreshToken'], response),
        365
      );
      setCookie(
        UID_COOKIE_NAME,
        path(['customer', 'customerId'], response),
        365
      );
      setCookie('r_email', path(['customer', 'email'], response), 365);
      setDeliverySlaCityId('');
      setDeliverySlaArea('');

      let newQuoteId = '';
      if (quote.quoteId) {
        newQuoteId = await migrateBag({ quoteId: quote.quoteId }, dispatch);
        if (newQuoteId) await setCurrentQuoteId(newQuoteId);
      }

      const { itemsCount: oldItemCount = 0 } = quote || {};
      await getUpdateQuoteDetails({}, shippingDispatch);

      const newBag = (await getStorage('currentQuote')) || {};

      const { itemsCount } = newBag;

      if (newQuoteId) {
        migrateMetaData({
          guestQuoteId: quote.quoteId,
          existingQuoteId: newQuoteId
        });
      }

      log('BEFORE DISPATCH', response);
      dispatch({
        type: ACTION_LOGIN_SUCCESS,
        data: { ...response, quoteId: newQuoteId || response.quoteId }
      });

      const eventData = {
        event_category: 'login_signup',
        method: loginType === 'MOBILE_LOGIN' ? 'sms' : 'password',
        value: useridentifier || phoneNumber,
        email: useridentifier,
        phone: phoneNumber
      };
      if (loginType === 'MOBILE_LOGIN') eventData.autoread = false;
      const cData = path(['customer'], response) || {};
      logEvent({
        eventName: 'login',
        data: eventData,
        additionalData: { ...cData, loginType },
        screenName: 'Login_User_Input'
      });
      let label = 'email';
      if (loginType === 'SOCIAL_LOGIN_GOOGLE') {
        label = 'google';
      } else if (loginType === 'SOCIAL_LOGIN_APPLE') {
        label = 'apple';
      } else if (loginType === 'MOBILE_LOGIN') {
        label = 'phone';
      }
      logEvent({
        eventName: 'CUSTOM_EVENT',
        data: {
          event_category: 'login',
          event_action: `successful_login_${eventActionFrom}`,
          event_label: label,
          value: useridentifier || phoneNumber,
          email: useridentifier,
          phone: phoneNumber
        },
        screenName: 'Login_User_Input'
      });
      if (response && response.registrationResponse === true) {
        try {
          logEvent({
            eventName: 'CUSTOM_EVENT',
            data: {
              event_category: 'login',
              event_action: `successful_signup_${eventActionFrom}`,
              event_label: label,
              value: useridentifier || phoneNumber,
              email: useridentifier,
              phone: phoneNumber
            },
            screenName: 'Login_User_Input'
          });
        } catch (error) {
          logError(error);
        }
      }
      if (fromBag) {
        if (itemsCount > oldItemCount) {
          dispatch({
            type: ACTION_LOGIN_REDIRECT,
            data: { redirectFromBag: false }
          });
          showToast(
            {
              type: 'success',
              content: translate("You've successfully logged in")
            },
            dispatch
          );
        }
      }

      getProfile({}, dispatch, shippingDispatch);
    }
  } catch (e) {
    logError(e);
    showToast({ type: 'error', content: translate(UNKNOWN_ERROR) }, dispatch);
    dispatch({
      type: ACTION_LOGIN_FALIURE,
      data: { invalidPassword: undefined, loginApiError: true }
    });
    loginFailureEventLog();
  }
}

export async function resetPassword(
  { currentPassword, customerId, newPassword },
  dispatch
) {
  dispatch({
    type: ACTION_RESET_PASSWORD_LOADING
  });
  try {
    const result = await axios.post(
      RESET_PASSWORD(),
      {
        currentPassword,
        customerId,
        newPassword
      },
      {
        headers: {
          Token: getJWT(),

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], result);
    const { statusCode, statusMsg } = data || {};

    if (statusCode === '200') {
      showToast(
        {
          type: 'success',
          content: translate('Password reset successfull')
        },
        dispatch
      );
      dispatch({
        type: ACTION_RESET_PASSWORD_SUCCESS,
        data: { successMsg: translate('Password reset successfull') }
      });
    } else if (statusCode === '201') {
      showToast(
        {
          type: 'error',
          content: translate(statusMsg) || UNKNOWN_ERROR
        },
        dispatch
      );
      dispatch({
        type: ACTION_RESET_PASSWORD_FALIURE,
        data: { errorMsg: translate(statusMsg) }
      });
    }
  } catch (e) {
    showToast(
      {
        type: 'error',
        content: UNKNOWN_ERROR
      },
      dispatch
    );
    dispatch({
      type: ACTION_RESET_PASSWORD_FALIURE,
      data: { errorMsg: UNKNOWN_ERROR }
    });
    logError(e);
  }
}

export async function forgotPassword(
  { useridentifier, loginType = 'EMAIL_LOGIN' },
  dispatch
) {
  dispatch({
    type: ACTION_FORGOT_PASSWORD_LOADING
  });
  const storeId = await getStoreId();
  const result = await axios.post(
    FORGOT_PASSWORD(),
    {
      loginType,
      storeId,
      useridentifier
    },
    {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
      }
    }
  );
  if (result.status === 200) {
    dispatch({
      type: ACTION_FORGOT_PASSWORD_SUCCESS
    });
    logEvent({
      eventName: 'user_auth_forgot_passwd',
      data: { event_category: 'login_signup' },
      screenName: 'Change_Password'
    });
  }
}

export async function createPassword({ token, newPassword }, dispatch) {
  dispatch({
    type: ACTION_CREATE_PASSWORD_LOADING
  });
  try {
    const result = await axios.post(
      CREATE_PASSWORD(),
      {
        token,
        newPassword
      },
      {
        headers: {
          Token: getJWT(),

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], result);
    const { statusCode } = data || {};
    const statusMsg = 'Password not reset successfully';
    if (statusCode === '200') {
      showToast(
        {
          type: 'success',
          content: translate('Password reset successfully'),
          timeOut: 6000
        },
        dispatch
      );
      dispatch({
        type: ACTION_CREATE_PASSWORD_SUCCESS,
        data: { successMsg: translate('Password reset successfully') }
      });
    } else {
      showToast(
        {
          type: 'error',
          content: translate(statusMsg) || UNKNOWN_ERROR,
          timeOut: 6000
        },
        dispatch
      );
      dispatch({
        type: ACTION_CREATE_PASSWORD_FALIURE,
        data: { errorMsg: translate(statusMsg) }
      });
    }
  } catch (e) {
    showToast(
      {
        type: 'error',
        content: UNKNOWN_ERROR,
        timeOut: 6000
      },
      dispatch
    );
    dispatch({
      type: ACTION_CREATE_PASSWORD_FALIURE,
      data: { errorMsg: UNKNOWN_ERROR }
    });
    logError(e);
  }
}

export const getInpageBanners = async dispatch => {
  try {
    const storeId = getStoreId();
    axios
      .get(`/strapi/in-page-banners?store.storeId=${storeId}`)
      .then(result => {
        const { data = [] } = result;
        const banners = {};
        data.forEach(banner => {
          banners[banner.pageName] = banner;
        });
        dispatch({ type: ACTION_GET_INPAGE_BANNERS, data: { banners } });
      });
  } catch (e) {
    logError(e);
  }
};

export const getRemoteConfig = async (options, dispatch) => {
  let rcFetched = false;
  let config = {};
  try {
    getInpageBanners(dispatch);
    const currentTimeStamp = new Date().getTime();
    const cookieTs = getCookieValue('rc_ts');
    const minuteDiff = cookieTs
      ? differenceInMinutes(new Date().getTime(), Number(cookieTs))
      : undefined;

    if (!cookieTs || minuteDiff > Number(RC_CACHE_TIME)) {
      setCookie('rc_ts', currentTimeStamp);
    }

    const latestCookieTs = getCookieValue('rc_ts');
    const response = await axios.get(
      `${process.env.STYLI_APP_REMOTE_CONFIG_ENDPOINT}?ts=${latestCookieTs}`
    );
    const rcData = (response && path(['data', 'data'], response)) || {};

    if (Object.keys(rcData).length) rcFetched = true;

    const rc = {
      getValue: rcKey => {
        if (rcKey && rcData[rcKey]) return rcData[rcKey];
        return '';
      }
    };

    const cpMode = rc.getValue('category_page_mode');
    const categoryPageMode = cpMode ? JSON.parse(cpMode) : '';
    const cf = rc.getValue('cloudfuntion_url');
    const tv = rc.getValue('tabby_config');
    const tm = rc.getValue('tamara_config');
    const sd = rc.getValue('shipping_data');
    const sl = rc.getValue('social_login');
    const pc = rc.getValue('payment_credentials');
    const ac = rc.getValue('algolia_config');
    const dc = rc.getValue('donation_configs');
    const scm = rc.getValue('search_suggestion_mode');
    const dt = rc.getValue('delivery_thresholds');
    const rdo = rc.getValue('return_drop_off');
    const vi = rc.getValue('vat_info');
    const translations = rc.getValue('translation_text');
    const pdpUSP = rc.getValue('pdp_usp');
    const isWebPushNotificationEnabled = rc.getValue('webpush_enabled');
    const isProductRatingEnabled = rc.getValue('product_rating_enabled');
    const ttc = rc.getValue('tabby_tamara_combined');
    const deleteUserValue = rc.getValue('delete_user');
    const vmUrl = rc.getValue('vm_url');
    const cdnOptimizedImage = rc.getValue('cdn_optimized_image');
    const categoryFilter = rc.getValue('category_filter');
    const privacy = rc.getValue('privacy_consent');

    let ratings = rc.getValue('ratings');
    ratings = ratings && JSON.parse(ratings);
    let plp = rc.getValue('plp');
    plp = plp && JSON.parse(plp);
    let pdpModelInfoEnabled = rc.getValue('pdp_model_information');
    pdpModelInfoEnabled =
      pdpModelInfoEnabled && JSON.parse(pdpModelInfoEnabled);
    const privacyConsent = privacy && JSON.parse(privacy);
    const tabbyConfig = tv && JSON.parse(tv);
    const tamaraConfig = tm && JSON.parse(tm);
    const tabbyTamaraCombinedConfig = ttc && JSON.parse(ttc);
    const deleteUser = deleteUserValue && JSON.parse(deleteUserValue);
    const vmUrlConfig = vmUrl && JSON.parse(vmUrl);
    const cdnOptimizedImageConfig =
      cdnOptimizedImage && JSON.parse(cdnOptimizedImage);

    const socialLogin = sl && JSON.parse(sl);

    const bt = rc.getValue('bank_transfer_credit');
    const bankTransferCreditConfig = bt && JSON.parse(bt);
    const vatInfo = vi && JSON.parse(vi);
    const algoliaConfig = ac && JSON.parse(ac);
    const searchConfig = scm && JSON.parse(scm);
    const returnDropOffConfig = rdo && JSON.parse(rdo);
    const donationConfigs = dc && JSON.parse(dc);
    const categoryFilterConfig = categoryFilter && JSON.parse(categoryFilter);

    let pdpNudge = rc.getValue('pdp_nudge_message');
    pdpNudge = pdpNudge
      ? JSON.parse(pdpNudge) || {
          [COUNTRY]: { [CURRENT_LANG_PREFIX]: [] }
        }
      : '';
    const freeShippingDifference = rc.getValue('free_shipping_difference');
    const paymentCredentials = JSON.parse(pc);
    let payfortCredentials;
    let cashFreeDetails;

    if (
      paymentCredentials &&
      paymentCredentials.credentials &&
      paymentCredentials.credentials[COUNTRY]
    ) {
      if (process.env.NODE_ENV === 'qa') {
        payfortCredentials = paymentCredentials.credentials[COUNTRY].sandBox;
        if (COUNTRY.toLowerCase() === 'in') {
          cashFreeDetails =
            paymentCredentials.credentials[COUNTRY].sandBox.cashfree;
        }
      } else if (process.env.NODE_ENV === 'development') {
        payfortCredentials = paymentCredentials.credentials[COUNTRY].dev;
        if (COUNTRY.toLowerCase() === 'in') {
          cashFreeDetails =
            paymentCredentials.credentials[COUNTRY].dev.cashfree;
        }
      } else if (process.env.NODE_ENV === 'uat') {
        payfortCredentials = paymentCredentials.credentials[COUNTRY].dev;
        if (COUNTRY.toLowerCase() === 'in') {
          cashFreeDetails =
            paymentCredentials.credentials[COUNTRY].uat.cashfree;
        }
      } else {
        payfortCredentials = paymentCredentials.credentials[COUNTRY].live;
        if (COUNTRY.toLowerCase() === 'in') {
          cashFreeDetails =
            paymentCredentials.credentials[COUNTRY].live.cashfree;
        }
      }
    }
    const paymentMethods = paymentCredentials?.credentials[COUNTRY]?.methods;
    const paymentExit = paymentCredentials.paymentExit[COUNTRY];
    const badgeMapper = rc.getValue('badge_mapper');
    const ageGroup = rc.getValue('age_group');
    const contactUs = rc.getValue('contact_us');
    const darkPatterns = rc.getValue('dark_patterns');
    const shippingDetails = rc.getValue('shipping_details');
    const restricedDeliveryMsg = rc.getValue('restricted_delivery_message');
    const smm = rc.getValue('sync_misc_metadata');
    const syncMiscMetadata = smm && JSON.parse(smm)[COUNTRY];

    const refundC = rc.getValue('refund_config');
    const refundConfig = refundC ? JSON.parse(refundC) : {};

    const msc = rc.getValue('mulin_size_chart');
    const sizeChartConfig = msc && JSON.parse(msc);

    const wfsC = rc.getValue('wfs_configuration');
    const wfsConfig = JSON.parse(wfsC)[COUNTRY];
    const ef = rc.getValue('estimated_flags');
    const dateFlags = JSON.parse(ef);
    const orderStateData = rc.getValue('order_status_states');
    const deliveryThresholds = JSON.parse(dt)[COUNTRY];
    const returnEligiblity = rc.getValue('return_eligiblity');
    const returnEligibleDays =
      JSON.parse(returnEligiblity) &&
      JSON.parse(returnEligiblity)[COUNTRY] &&
      JSON.parse(returnEligiblity)[COUNTRY].eligible_days;

    let courierPartnerInfo = rc.getValue('courier_partner_info');
    courierPartnerInfo = JSON.parse(courierPartnerInfo);

    const bagNudgeData = rc.getValue('bag_nudge');
    const bagNudge = JSON.parse(bagNudgeData);

    const crossSellConfigData = rc.getValue('cross_sell_config');
    const serviceStatusR = rc.getValue('service_status');
    const serviceStatus = JSON.parse(serviceStatusR)[process.env.NODE_ENV];
    const crossSellConfig = JSON.parse(crossSellConfigData);

    const shippingData = JSON.parse(sd);
    const shippingCharge = Number(
      (shippingData[COUNTRY] && shippingData[COUNTRY].shipping_charge) ||
        DEFAULT_SHIPPING_CHARGE
    );
    const minBagValueForFreeShipping = Number(
      (shippingData[COUNTRY] &&
        shippingData[COUNTRY].min_bag_value_for_free_shipping) ||
        DEFAULT_MIN_BAG_VALUE_FREE_SHIP
    );

    const discountThresholdBag = Number(
      shippingData[COUNTRY] && shippingData[COUNTRY].show_discount_threshold_bag
    );

    const sortedDeliveryThreshHold = deliveryThresholds?.sort((a, b) =>
      a.threshHoldLess < b.threshHoldLess ? 1 : -1
    );
    const restricedDeliveryMessage =
      restricedDeliveryMsg &&
      JSON.parse(restricedDeliveryMsg) &&
      JSON.parse(restricedDeliveryMsg)[COUNTRY] &&
      JSON.parse(restricedDeliveryMsg)[COUNTRY][CURRENT_LANG_PREFIX];

    const cloudFunctionObj = cf && JSON.parse(cf);
    let cloudFunctionUrl = '';
    if (process.env.NODE_ENV === 'qa') {
      cloudFunctionUrl = cloudFunctionObj.qa;
    } else if (process.env.NODE_ENV === 'uat') {
      cloudFunctionUrl = cloudFunctionObj.uat;
    } else if (process.env.NODE_ENV === 'development') {
      cloudFunctionUrl = cloudFunctionObj.dev;
      if (
        process.env.STYLI_APP_WEBSITE_DOMAIN === 'local.stylifashion.com:3000'
      ) {
        cloudFunctionUrl = cloudFunctionObj.qa;
      }
    } else {
      cloudFunctionUrl = cloudFunctionObj.production;
    }

    const orderStateAndStatus = JSON.parse(orderStateData);
    const qdt = rc.getValue('quoteDownTime');
    const disableBag = JSON.parse(qdt);
    const deliverySlaConfigs = rc.getValue('delivery_sla_configs');
    const rn = rc.getValue('return_nudge');
    const returnNudge = rn && JSON.parse(rn);
    const newAddress = rc.getValue('new_address');
    const rs = rc.getValue('recommend_sys');
    const recommendSys = rs && JSON.parse(rs);
    let otpVerfication = rc.getValue('otp_verfication');
    otpVerfication = otpVerfication && JSON.parse(otpVerfication);
    let beautyProductConfig = rc.getValue('beauty_product_config');
    beautyProductConfig =
      beautyProductConfig && JSON.parse(beautyProductConfig);

    const blc = rc.getValue('bag_loader_config');
    const bagLoaderConfig = blc && JSON.parse(blc);
    const easyCheckoutEnabled = bagLoaderConfig && bagLoaderConfig.easyCheckout;
    let getStaticPageFromStrapi = rc.getValue('get_static_page_from_strapi');
    getStaticPageFromStrapi = JSON.parse(getStaticPageFromStrapi || 'false');

    const otpC = rc.getValue('otp_verfication');
    const otpConfig = otpC && JSON.parse(otpC);

    const wishlist = rc.getValue('wishlist_config');
    const wishlistConfig = wishlist && JSON.parse(wishlist);
    const sessionInvalidation = rc.getValue('session_invalidation');
    const sessionInvalidationConfig =
      sessionInvalidation && JSON.parse(sessionInvalidation);

    let returnInformationConfig = rc.getValue('return_information_config');
    returnInformationConfig =
      returnInformationConfig && JSON.parse(returnInformationConfig);

    let couponPopupConfigs = rc.getValue('coupon_popup_configs');
    couponPopupConfigs = couponPopupConfigs && JSON.parse(couponPopupConfigs);

    let inappNotification = rc.getValue('web_inapp_notification');
    inappNotification = inappNotification && JSON.parse(inappNotification);

    let appPromoBanner = rc.getValue('app_promo_banner');
    appPromoBanner = appPromoBanner && JSON.parse(appPromoBanner);

    let paymentFailedHold = rc.getValue('payment_failed_hold');
    paymentFailedHold = paymentFailedHold && JSON.parse(paymentFailedHold);

    // Getting Coupon Info for PDP
    const pdpConfig = rc.getValue('pdp');
    const parsedPdpConfig = pdpConfig ? JSON.parse(pdpConfig) : {};
    const pdpCouponConfig = parsedPdpConfig.pdp_coupon || {};
    // Getting no coupon categories for PDP
    const pdpNoCouponCategories = parsedPdpConfig.noCouponCategory || [];

    const countryCouponConfig = pdpCouponConfig[COUNTRY] || {};
    const translationsForCoupon = countryCouponConfig.translations
      ? countryCouponConfig.translations[CURRENT_LANG_PREFIX]
      : {};

    const pdpCouponConfigObj = {
      ...countryCouponConfig,
      translations: translationsForCoupon
    };

    config = {
      pdpCouponConfigObj,
      pdpNoCouponCategories,
      shippingCharge,
      easyCheckoutEnabled,
      textContent:
        JSON.parse(translations) &&
        JSON.parse(translations)[COUNTRY] &&
        JSON.parse(translations)[COUNTRY][CURRENT_LANG_PREFIX],
      searchConfig,
      recommendSys,
      returnNudge,
      returnDropOffConfig,
      pdpUSP: JSON.parse(pdpUSP)[COUNTRY],
      pdpNudge:
        pdpNudge && pdpNudge[COUNTRY] && pdpNudge[COUNTRY][CURRENT_LANG_PREFIX],

      deliveryThresholds: sortedDeliveryThreshHold,
      minBagValueForFreeShipping,
      freeShippingDifference: Number(freeShippingDifference),
      badgeMapper: JSON.parse(badgeMapper)[COUNTRY]
        ? arrayToObj(
            JSON.parse(badgeMapper)[COUNTRY],
            `badge_${CURRENT_LANG_PREFIX}`
          )
        : {},
      syncMiscMetadata,
      restricedDeliveryMessage,
      cloudFunctionUrl,
      orderStateAndStatus,
      payfortCredentials,
      cashFreeDetails,
      dateFlags,
      returnEligibleDays,
      ageGroup: JSON.parse(ageGroup),
      contactUs: JSON.parse(contactUs),
      darkPatterns: JSON.parse(darkPatterns),
      shippingDetails: JSON.parse(shippingDetails),
      applePayEnabled:
        paymentMethods && paymentMethods.indexOf('APPLE_PAY') >= 0,
      cardEnabled: paymentMethods && paymentMethods.indexOf('cc_dc') >= 0,
      walletEnabled: paymentMethods && paymentMethods.indexOf('WALLET') >= 0,
      codEnabled: paymentMethods && paymentMethods.indexOf('COD') >= 0,
      categoryPageMode,
      minRefundDate: refundConfig.minimum_refund_date_ms,
      disableBag,
      wfsConfig,
      sizeChartConfig,
      algoliaConfig,
      discountThresholdBag,
      deliverySlaConfigs: JSON.parse(deliverySlaConfigs),
      addressBucketInfo: JSON.parse(newAddress),
      donationConfigs,
      vatInfo,
      isWebPushNotificationEnabled,
      beautyProductConfig,
      isProductRatingEnabled,
      ratings,
      pdpModelInfoEnabled,
      otpVerfication,
      getStaticPageFromStrapi,
      tabbyConfig,
      privacyConsent,
      tamaraConfig,
      socialLogin,
      courierPartnerInfo,
      bagNudge,
      crossSellConfig,
      plp,
      bankTransferCreditConfig,
      otpConfig,
      wishlistConfig,
      sessionInvalidationConfig,
      bagLoaderConfig,
      tabbyTamaraCombinedConfig,
      paymentExit,
      deleteUser,
      vmUrlConfig,
      cdnOptimizedImageConfig,
      indDisable: serviceStatus?.indDisable,
      returnConfigIndia: returnInformationConfig.india_config,
      categoryFilterConfig,
      couponPopupConfigs,
      inappNotification,
      appPromoBanner: appPromoBanner.app_promo_banner || {},
      paymentFailedHold
    };
  } catch (e) {
    console.log('Error fetching KV remote config');
    logError(e);
  }

  if (!rcFetched) {
    try {
      const jsonPath =
        ['qa', 'uat'].indexOf(process.env.NODE_ENV) > -1
          ? `/resources/${COUNTRY}/qa-${COUNTRY}.json`
          : `/resources/${COUNTRY}/${COUNTRY}.json`;
      const configJsonResponse = await axios.get(jsonPath);
      config = path(['data'], configJsonResponse);
      console.log(`static config being used : ${jsonPath}`);
    } catch (e) {
      console.log('error fetching static config');
      logError(e);
    }
  }

  dispatch({ type: ACTION_CONFIG_VALUES, data: { config } });
  setStorage('config', config);
};

export async function getStyliConfig(optss, dispatch) {
  let siteConfigResult = {};
  try {
    siteConfigResult = await axios.get(GET_CONFIG());

    // const stores = path(
    //   ['data', 'response', 'environments', 0, 'stores'],
    //   siteConfigResult
    // );
    const stores =
      siteConfigResult &&
      siteConfigResult.data &&
      siteConfigResult.data.response &&
      siteConfigResult.data.response.environments &&
      siteConfigResult.data.response.environments[0] &&
      siteConfigResult.data.response.environments[0].stores;
    // const code = path(['data', 'response', 'code'], siteConfigResult);
    const code =
      siteConfigResult &&
      siteConfigResult.data &&
      siteConfigResult.data.response &&
      siteConfigResult.data.response.code;
    const currentStoreId = getStoreId();
    const siteConfig = stores.find(
      store => Number(store.storeId) === currentStoreId
    );
    siteConfig.code = code;
    setStorage('siteConfig', siteConfig);
    dispatch({ type: ACTION_SITE_CONFIG_VALUES, data: { siteConfig } });
    // return result;
  } catch (e) {
    // logError({ message: "error in getStyliConfig", siteConfigResult, customError: 1 })
    logError(e);
  }
  return false;
}

export const profileUpdate = async (
  {
    ageGroupId,
    firstName,
    gender,
    lastName,
    mobileNumber,
    isUpdateGender = false
  },
  dispatch
) => {
  let hasError = false;
  let errorMsg = '';

  dispatch({
    type: ACTION_PROFILE_UPDATE_LOADING
  });

  try {
    const customerId = getCustomerId();
    const profileUpodateResponse = await axios.post(
      PROFILE_UPDATE_API(),
      {
        ageGroupId,
        firstName,
        gender,
        lastName,
        mobileNumber,
        customerId
      },
      {
        headers: {
          Token: getJWT(),

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], profileUpodateResponse);
    const { statusCode, statusMsg, response } = data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_PROFILE_UPDATE_SUCCESS
      });
    } else {
      hasError = true;
      errorMsg = response.userMessage || statusMsg;
      dispatch({
        type: ACTION_PROFILE_UPDATE_FAILURE
      });
    }
  } catch (e) {
    logError(e);
    hasError = true;
    errorMsg = UNKNOWN_ERROR;
    dispatch({
      type: ACTION_PROFILE_UPDATE_FAILURE
    });
  }

  showToast(
    {
      type: hasError ? 'error' : 'success',
      timeOut: isUpdateGender ? 3000 : 2000,
      ctaAction: isUpdateGender ? 'account' : undefined,
      content: hasError
        ? translate(errorMsg)
        : translate(
            isUpdateGender
              ? 'Successfully updated your profile info'
              : 'Profile updated successfully'
          )
    },
    dispatch
  );
};

export async function getTabbyOrderDetails({ tabbyPaymentId }, dispatch) {
  const customerId = getCustomerId();
  // const storeId = getStoreId();
  try {
    dispatch({
      type: ACTION_ORDER_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const orderResponse = await axios.post(
      GET_TABBY_ORDER_DETAILS({ paymentId: tabbyPaymentId }),
      {},
      {
        headers: {
          Token: jwt,

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    // if (statusCode === '200' && customerId === response.customerId) {
    if (statusCode === '200') {
      dispatch({
        type: ACTION_ORDER_DETAILS_FETCHED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_ORDER_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_ORDER_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function getOrderDetails({ orderId, isArchive }, dispatch) {
  const storedData = await getStorage('orderResponse');
  const storeId = getStoreId() || storedData?.storeId;
  const customerId = getCustomerId() || storedData?.customerId;
  try {
    dispatch({
      type: ACTION_ORDER_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const orderResponse = await axios.post(
      GET_ORDER_DETAILS(),
      {
        customerId,
        storeId,
        orderId,
        useArchive: isArchive
      },
      {
        headers: {
          Token: jwt,

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    // if (statusCode === '200' && customerId === response.customerId) {
    if (statusCode === '200') {
      const storeVal = {
        customerId: data?.response?.customerId,
        incrementId: data?.response?.incrementId,
        orderId: data?.response?.orderId,
        quote: data?.response,
        quoteId: data?.response?.quoteId,
        storeId: data?.response?.storeId,
        method: data?.paymentMethod
      };
      await setStorage('orderResponse', storeVal);
      dispatch({
        type: ACTION_ORDER_DETAILS_FETCHED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_ORDER_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_ORDER_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function getPayableOrder(dispatch) {
  const customerId = getCustomerId();
  let statusCodeVal = '';
  const storeId = getStoreId();
  try {
    dispatch({
      type: ACTION_PAYABLE_ORDERS_LOADING
    });

    const jwt = getJWT();

    const orderResponse = await axios.post(
      GET_PAYABLE_ORDERS(),
      {
        customerId,
        storeId
      },
      {
        headers: {
          Token: jwt,
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    // if (statusCode === '200' && customerId === response.customerId) {
    if (statusCode === '200') {
      dispatch({
        type: ACTION_PAYABLE_ORDERS_FETCHED,
        data: response
      });
      statusCodeVal = '200';
    } else {
      dispatch({ type: ACTION_PAYABLE_ORDERS_ERROR });
      statusCodeVal = '201';
    }
  } catch (e) {
    dispatch({ type: ACTION_PAYABLE_ORDERS_ERROR });
    logError(e);
  }
  return statusCodeVal;
}

export async function initRMA({ orderId, returnData }, dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  try {
    dispatch({
      type: ACTION_INIT_RMA_LOADING
    });
    const items = returnData.map(item => {
      return {
        parentOrderItemId: item.parentOrderItemId,
        returnQuantity: item.toReturnQuantity
      };
    });

    const dataResponse = await axios.post(
      // const dataResponse = await axios.get(
      GET_INIT_RMA(),
      {
        customerId,
        storeId,
        orderId,
        items
      },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], dataResponse);
    const { statusCode, response } = data || {};
    // if (statusCode === '200' && customerId === response.customerId) {
    if (statusCode === '200') {
      dispatch({
        type: ACTION_INIT_RMA_FETCHED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response && response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function initCancel(options, dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  const { orderId } = options;
  if (customerId) {
    dispatch({ type: ACTION_ORDER_INIT_LOADING });

    try {
      const result = await axios.post(
        API_CANCEL_ORDER_INIT(),
        {
          customerId,
          storeId,
          orderId
        },
        {
          headers: {
            Token: getJWT(),
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      const { response } = result.data || {};

      dispatch({
        type: ACTION_ORDER_INIT_LOADED,
        data: response && response.reasons
      });
    } catch (e) {
      logError(e);
      showToast({ type: 'error', content: UNKNOWN_ERROR }, dispatch);
    }
  }
}

export async function cancelOrder(options, dispatch) {
  dispatch({ type: ACTION_ORDER_CANCEL_LOADING });
  const customerId = getCustomerId();
  const storeId = getStoreId();
  const { orderId, reason, reasonId } = options;
  let orderCancelled = false;
  try {
    const result = await axios.post(
      API_CANCEL_ORDER(),
      {
        customerId,
        storeId,
        orderId,
        reason,
        reasonId
      },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response, statusMsg } = result.data || {};

    if (statusCode === '200') {
      await getOrderDetails({ orderId }, dispatch);
      showToast(
        { type: 'success', content: translate('Order has been cancelled') },
        dispatch
      );

      dispatch({
        type: ACTION_ORDER_CANCELED,
        data: { orderCancelData: response }
      });
      orderCancelled = true;
    } else {
      showToast(
        { type: 'success', content: `Error cancelling Order ${statusMsg}` },
        dispatch
      );
    }
  } catch (e) {
    logError(e.message);
  }
  return { orderCancelled };
}
export async function processReturn(options, dispatch) {
  dispatch({ type: ACTION_RETURN_ITEM_LOADING });
  const customerId = getCustomerId();
  const storeId = getStoreId();
  const { orderId, items, isDropOffRequest, rmaPaymentMethod } = options;
  let itemReturned = false;

  try {
    const result = await axios.post(
      API_RETURN_ORDER(),
      {
        customerId,
        storeId,
        orderId,
        items,
        isDropOffRequest,
        rmaPaymentMethod
      },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response } = result.data || {};

    if (statusCode === '200') {
      // await getReturnOrderList( {}, dispatch);
      // showToast({ type: 'success', content: statusMsg }, dispatch);

      dispatch({
        type: ACTION_RETURN_ITEM_LOADED,
        data: { itemReturnData: response }
      });
      itemReturned = true;
    } else {
      dispatch({
        type: ACTION_RETURN_ITEM_ERROR
      });
      showToast({ type: 'success', content: UNKNOWN_ERROR }, dispatch);
    }
  } catch (e) {
    logError(e.message);
    dispatch({
      type: ACTION_RETURN_ITEM_ERROR
    });
    showToast({ type: 'success', content: UNKNOWN_ERROR }, dispatch);
  }
  return { itemReturned };
}

export async function cancelRMA(options, dispatch) {
  dispatch({ type: ACTION_CANCEL_RMA_LOADING });
  const customerId = getCustomerId();
  const storeId = getStoreId();
  const { requestId } = options;
  let rmaCanceled = false;
  try {
    const result = await axios.post(
      API_CANCEL_RMA(),
      {
        customerId,
        storeId,
        requestId
      },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response, statusMsg } = result.data || {};

    if (statusCode === '200') {
      // await getReturnOrderList( {}, dispatch);
      // showToast({ type: 'success', content: statusMsg }, dispatch);

      dispatch({
        type: ACTION_CANCEL_RMA_LOADED,
        data: { cancelRMAData: response }
      });
      rmaCanceled = true;
    } else {
      showToast(
        { type: 'success', content: `Error cancelling Return :  ${statusMsg}` },
        dispatch
      );
    }
  } catch (e) {
    logError(e.message);
  }
  return { rmaCanceled };
}

export async function setReturnDetails(options, dispatch) {
  dispatch({
    type: ACTION_RETURN_DATA_UPDATE,
    data: { ...options }
  });
}

export async function getDefaultAddress(dispatch) {
  try {
    dispatch({
      type: ACTION_DEFAULT_CITY_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }
    const cEnv =
      process.env.NODE_ENV === 'development' ? 'qa' : process.env.NODE_ENV;
    const defaultAddressResponse = await axios.get(
      `/broute/${cEnv}/address_${COUNTRY}.json`
    );
    const data = path(['data'], defaultAddressResponse);
    const statusCode = path(['status'], defaultAddressResponse);
    if (statusCode === 200) {
      dispatch({
        type: ACTION_DEFAULT_CITY_DETAILS_FETCHED,
        data
      });
    } else {
      const toastContent = UNKNOWN_ERROR;
      dispatch({ type: ACTION_DEFAULT_CITY_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_DEFAULT_CITY_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function getCityDetails(
  {
    citySearchKey = '',
    defaultCityId,
    updateLocal,
    updateLocalArea = false,
    isPincodeSearch = false
  },
  dispatch
) {
  try {
    dispatch({
      type: ACTION_CITY_DETAILS_LOADING
    });
    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    // const cEnv = process.env.NODE_ENV === 'development' ? 'qa' : process.env.NODE_ENV;
    const cityResponse = await axios.get(
      `${GET_CITY_DETAILS()}/${citySearchKey}`,
      // `https://adrsmpr-${cEnv}.stylishop.store/api/address/search/city/${citySearchKey}`,
      {
        headers: {
          Token: jwt,
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], cityResponse);
    const { statusCode, response } = data || {};
    if (statusCode === 200) {
      const { id: pdpDeliveryCity, name_en: pdpDeliveryArea } = response || {};
      if (updateLocal) await setDeliverySlaCityId(pdpDeliveryCity);
      if (updateLocalArea) await setDeliverySlaArea(pdpDeliveryArea);
      dispatch({
        type: ACTION_CITY_DETAILS_FETCHED,
        data: response
      });
    }
  } catch (e) {
    dispatch({ type: ACTION_CITY_DETAILS_ERROR });
    const { status } = e.response || {};

    if (!isPincodeSearch && status === 400) {
      if (defaultCityId) {
        const cityResponseNew = await axios.get(
          `${GET_CITY_DETAILS()}/${defaultCityId}`,
          {
            headers: {
              'X-Header-Token': getXHeaderToken(),
              'Content-Type': 'application/json'
            }
          }
        );
        const dataNew = path(['data'], cityResponseNew);

        const { statusCode: statusCodeNew, response: responseNew } =
          dataNew || {};
        if (statusCodeNew === 200) {
          const { id: pdpDeliveryCityNew, name_en: pdpDeliveryArea } =
            responseNew || {};
          if (updateLocal) await setDeliverySlaCityId(pdpDeliveryCityNew);
          if (updateLocalArea) await setDeliverySlaArea(pdpDeliveryArea);
          dispatch({
            type: ACTION_CITY_DETAILS_FETCHED,
            data: responseNew
          });
        } else {
          const toastContent = UNKNOWN_ERROR;
          dispatch({ type: ACTION_CITY_DETAILS_ERROR });
          showToast(
            { type: 'error', content: toastContent, timeOut: 4000 },
            dispatch
          );
        }
      }
    } else {
      showToast(
        { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
        dispatch
      );
      logError(e);
    }
  }
}

export async function getSearchData({ query, limit, level1 }, dispatch) {
  try {
    let env = 'live';
    switch (process.env.NODE_ENV) {
      case 'qa':
        env = 'qa';
        break;
      case 'development':
        env = 'dev';
        break;
      case 'uat':
        env = 'uat';
        break;
      default:
    }
    const storeId = getStoreId();
    if (query === '') {
      dispatch({ type: ACTION_POPULAR_SEARCH_DATA_LOADING });
    } else {
      dispatch({ type: ACTION_SEARCH_DATA_LOADING });
    }
    const response = await axios.post(
      GET_AUTO_COMPLETE(),
      {
        query,
        storeId,
        env,
        limit,
        category_l1: level1 || 'Women'
      },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    if (query === '') {
      const popularSearchResponse = path(['data', 'additional', [0]], response);
      dispatch({
        type: ACTION_POPULAR_SEARCH_DATA_FETCHED,
        data: { ...popularSearchResponse }
      });
    } else {
      const searchResponse = path(['data', 'autocomplete'], response);
      dispatch({ type: ACTION_SEARCH_DATA_FETCHED, data: searchResponse });
    }
  } catch (e) {
    if (query === '') {
      dispatch({ type: ACTION_POPULAR_SEARCH_DATA_ERROR });
    } else {
      dispatch({ type: ACTION_SEARCH_DATA_ERROR });
    }
  }
}

export const getDesktopCategories2 = async (option, dispatch) => {
  const startTime = new Date().getTime();
  const result = await axios.get(
    `/strapi_pages/processed/category/${
      COUNTRY !== COUNTRY_KSA ? `${COUNTRY}_` : ''
    }${CURRENT_LANG_PREFIX}.json`
  );
  dispatch({ type: ACTION_GET_DESKTOP_CATEGORIES2, data: result.data });
  const endTime = new Date().getTime();
  timingVisualComplete(endTime - startTime);
};

export const getBannerImageCards = async dispatch => {
  setTimeout(() => {
    if (window.appboy) {
      const { cards = [] } = window.appboy.getCachedContentCards();
      const sortByUpdatedDateDesc = cards.sort((a, b) => b.date - a.date);

      dispatch({
        type: ACTION_GET_BANNER_IMAGE_CARDS,
        data: sortByUpdatedDateDesc
      });
    }
  }, 13000);
};
export async function getRateDetails({ order }, dispatch) {
  const customerId = getCustomerId();
  const request = [];
  const { products, orderId } = order;
  products.forEach(e =>
    request.push({
      parentSku: e.parentSku,
      childSku: e.sku
    })
  );
  try {
    dispatch({
      type: ACTION_RATE_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const orderResponse = await axios.post(
      GET_RATE_DETAILS(),
      {
        customerId,
        orderId,
        products: request
      },
      {
        headers: {
          Token: jwt,

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    // if (statusCode === '200' && customerId === response.customerId) {
    if (statusCode === '200') {
      dispatch({
        type: ACTION_RATE_DETAILS_FETCHED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_RATE_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_RATE_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function saveRatings(
  rating,
  delivery,
  dispatch,
  custEmail,
  shippingAddress
) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  let customerName = '';
  let customerEmail = '';
  const space = ' ';
  let orderId = {};
  const user = await getStorage('profile');
  if (user) {
    const { email, firstName, lastName } = user.customer;
    customerName = firstName + space + lastName;
    customerEmail = email;
  } else {
    customerEmail = custEmail;
    if (shippingAddress) {
      const { firstName, lastName } = shippingAddress;
      if (firstName && lastName) customerName = firstName + space + lastName;
    }
  }
  try {
    dispatch({
      type: ACTION_SAVE_RATE_DETAILS_LOADING
    });
    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }
    let apiRatings = [...rating];
    apiRatings = apiRatings.map(a => {
      let temp = a;
      delete temp.questions;
      if (temp.rate === null) temp = {};
      return temp;
    });
    let deliveryRatings = {};
    if (!isEmpty(delivery)) {
      deliveryRatings = { ...delivery };
      delete deliveryRatings.questions;
      delete deliveryRatings.orderId;
      delete deliveryRatings.customerEmail;
    }
    if (rating && rating[0]) orderId = rating[0].orderId;
    let orderResponse = {};
    if (isEmpty(deliveryRatings)) {
      orderResponse = await axios.post(
        SAVE_RATE_DETAILS(),
        {
          customerId,
          customerEmail,
          customerName,
          storeId,
          orderId,
          ratings: apiRatings
        },
        {
          headers: {
            Token: jwt,
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
    } else {
      orderResponse = await axios.post(
        SAVE_RATE_DETAILS(),
        {
          customerId,
          customerEmail,
          customerName,
          storeId,
          orderId,
          ratings: apiRatings,
          deliveryRatings
        },
        {
          headers: {
            Token: jwt,
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
    }
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_RATE_DETAILS_SAVED,
        data: statusCode
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });
    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function updateRatings(ratings, dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  let customerEmail = {};

  const user = await getStorage('profile');
  if (user) {
    const { email } = user.customer;
    customerEmail = email;
  }

  try {
    dispatch({
      type: ACTION_SAVE_RATE_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const apiRatings = [...ratings];

    apiRatings.map(request => {
      request.customerId = customerId ? customerId.toString() : '';
      let { options } = request;
      const { rate } = request;
      options = options.map(String);
      request.options = options;
      request.customerEmail = customerEmail;
      request.rate = rate ? rate.toString() : '';
      request.storeId = storeId ? storeId.toString() : '';

      return request;
    });

    const apiRequest = apiRatings.length > 0 ? apiRatings[0] : null;
    delete apiRequest.questions;

    const orderResponse = await axios.put(
      SAVE_RATE_DETAILS(),
      {
        ...apiRequest
      },
      {
        headers: {
          Token: jwt,

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_RATE_DETAILS_SAVED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function removeRatings(orderno, childSku, dispatch) {
  const customerId = getCustomerId().toString();

  try {
    dispatch({
      type: ACTION_SAVE_RATE_DETAILS_LOADING
    });

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    const request = { orderId: orderno.toString(), customerId, childSku };
    const orderResponse = await axios.delete(SAVE_RATE_DETAILS(), {
      data: { ...request },
      headers: {
        Token: jwt,

        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
      }
    });
    const data = path(['data'], orderResponse);
    const { statusCode, response } = data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_RATE_DETAILS_SAVED,
        data: response
      });
    } else {
      let toastContent = UNKNOWN_ERROR;
      if (customerId !== response.customerId) {
        toastContent = 'Order does not belong to customer';
      }
      dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_SAVE_RATE_DETAILS_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function verifyRecaptcha(recaptchaToken, dispatch) {
  try {
    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }
    const recaptchaResponse = await axios.post(VERIFY_RECAPTCHA(), {
      token: recaptchaToken,
      headers: {
        Token: jwt,

        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
      }
    });
    const data = path(['data'], recaptchaResponse);
    const { statusCode, response } = data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_RECAPTCHA_VERIFIED,
        data: response
      });
    } else {
      dispatch({ type: ACTION_RECAPTCHA_ERROR });
      showToast(
        { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_RECAPTCHA_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function getWishListCount({ noLoading = false }, dispatch) {
  const customerId = getCustomerId();
  const startTime = new Date().getTime();

  if (customerId) {
    try {
      const storeId = getStoreId();
      if (!storeId) {
        return;
      }
      if (!noLoading) {
        dispatch({ type: ACTION_GET_WISHLIST_COUNT_LOADING });
      }
      const result = await axios.post(
        GET_WISHLIST({ customerId, storeId }),
        { customerId, storeId },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );

      const response = (result.data && result.data.response) || result.data; // slight confustion with the api
      dispatch({ type: ACTION_GET_WISHLIST_COUNT, data: { ...response } });
      const endTime = new Date().getTime();
      timingVisualComplete(endTime - startTime);
    } catch (e) {
      dispatch({ type: ACTION_GET_WISHLIST_COUNT_FAILURE });
      logError(e);
    }
  } else {
    dispatch({
      type: ACTION_GET_WISHLIST_COUNT_FAILURE,
      data: { loginRequired: true }
    });
  }
}

export async function getBankNamesAndSwiftCode(dispatch) {
  dispatch({ type: ACTION_BANK_NAMES_LOADING });
  try {
    const result = await axios.get(
      GET_BANK_NAMES_AND_SWIFT_CODES(),
      {},
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response } = result.data || {};
    if (statusCode === '200') {
      dispatch({
        type: ACTION_BANK_NAMES_FETCHED,
        data: response
      });
    } else {
      const toastContent = UNKNOWN_ERROR;
      dispatch({ type: ACTION_BANK_NAMES_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_BANK_NAMES_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
}

export async function createBankTransfer(bankObj, dispatch) {
  dispatch({ type: ACTION_CREATE_BANK_TRANSFER_LOADING });
  let created = false;
  const reqObj = { ...bankObj };
  reqObj.customerId = getCustomerId();
  reqObj.storeId = getStoreId();

  try {
    const result = await axios.post(
      CREATE_BANK_TRANSFER(),
      { ...reqObj },
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const { statusCode, response, statusMsg } = result.data || {};
    if (statusCode === '200' || statusCode === '201') {
      created = true;
      dispatch({
        type: ACTION_BANK_TRANSFER_CREATED,
        data: response
      });
    } else {
      const toastContent = statusMsg;
      dispatch({ type: ACTION_CREATE_BANK_TRANSFER_ERROR });
      showToast(
        { type: 'error', content: toastContent, timeOut: 4000 },
        dispatch
      );
    }
  } catch (e) {
    dispatch({ type: ACTION_CREATE_BANK_TRANSFER_ERROR });

    showToast(
      { type: 'error', content: UNKNOWN_ERROR, timeOut: 4000 },
      dispatch
    );
    logError(e);
  }
  return created;
}
export async function deleteAccount(data, dispatch) {
  let result = {};
  try {
    result = await axios.post(
      DELETE_REQUEST(),
      { ...data },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    if (result?.data?.status) {
      dispatch({ type: ACTION_LOGOUT });
      deleteCookie(JWT_COOKIE_NAME);
      deleteCookie(REFRESH_TOKEN);
      deleteCookie(UID_COOKIE_NAME);
      deleteCookie('r_email');
      await getJwtFromEmail({ email: 'guest@stylishop.com' });
      await logoutStorage();
      window.location.href = window.location.origin;
      sessionStorage.setItem('DELETEDACCOUNT', true);
      showToast(
        {
          type: 'success',
          content: translate('Account deleted successfully'),
          timeOut: 4000
        },
        dispatch
      );
    } else {
      showToast(
        {
          type: 'error',
          content: translate('Failed to delete account. Please try again'),
          timeOut: 4000
        },
        dispatch
      );
    }
  } catch (e) {
    logError(e);
    loginFailureEventLog();

    showToast(
      {
        type: 'error',
        content: translate('An error occurred while deleting account'),
        timeOut: 4000
      },
      dispatch
    );
  }
  return result;
}
export async function withdrawDelete(data) {
  let result = {};
  try {
    result = await axios.post(
      DELETE_REQUEST(),
      { ...data },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (e) {
    logError(e);
    loginFailureEventLog();
  }
  return result;
}
