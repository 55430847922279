import axios from 'utils/axios';
import { getCookieValue, path, setCookie } from '.';
import { API_JWT_FROM_EMAIL, GET_CONFIG } from '../constants/api';
import logError from './logError';
import { JWT_COOKIE_NAME, UID_COOKIE_NAME } from '../constants';
import { getStorage, setStorage } from './storage';

export function getCustomerId() {
  const customerId =
    getCookieValue(JWT_COOKIE_NAME) && getCookieValue(UID_COOKIE_NAME);
  return customerId ? parseInt(customerId, 10) : '';
}
export function getEmail() {
  return getCookieValue('r_email');
}

export function getXHeaderToken(emailInput = '') {
  // const customerId = getCustomerId();
  // if (!customerId)
  //   return 'guest@stylishop.com';
  const email = emailInput || getEmail();
  return email || 'guest@stylishop.com';
}

export function decodeJWT(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export function isTwoMinutesBeforeExpiration(decodedJwt) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const twoMinutesBeforeExp = decodedJwt.exp - 120;

  return currentTimestamp >= twoMinutesBeforeExp;

  // console.log(decodedJwt);
  // return true
}

export async function getJwtFromEmail({ email }) {
  try {
    const customerId = getCustomerId();
    if (!customerId) {
      const siteConfig = await getStorage('siteConfig');
      let jwtCode = siteConfig && siteConfig.code;

      if (!jwtCode) {
        const siteConfigResult = await axios.get(GET_CONFIG());

        jwtCode = path(['data', 'response', 'code'], siteConfigResult);
      }

      const result = await axios.post(
        API_JWT_FROM_EMAIL(),
        {
          email
          // code: jwtCode
        },
        {
          headers: {
            'Content-type': 'application/json'
          }
        }
      );
      const jwt = path(['data', 'jwtToken'], result);
      const status = path(['data', 'status'], result);
      if (status && jwt) {
        setCookie(JWT_COOKIE_NAME, jwt, 30);
        return jwt;
      }
      return false;
    }
  } catch (e) {
    logError(e);
  }
  return false;
}

export async function getAgeGroupId() {
  try {
    const ageGroup = await getStorage('ageGroup');
    return Number(ageGroup) || 1;
  } catch (e) {
    logError(e);
  }
  return 1;
}

export async function getSmsaVersion() {
  const dropOffVersion = await getStorage('smsaVersion');
  return Number(dropOffVersion || 0);
}

export async function setSmsaVersion(version) {
  await setStorage('smsaVersion', version);
}

export async function getReturnChangeVersion() {
  const returnChangeVersion = await getStorage('returnChangeVersion');
  return Number(returnChangeVersion || 0);
}

export async function setReturnChangeVersion(version) {
  await setStorage('returnChangeVersion', version);
}
