import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { IS_ARABIC, OTP_TIMER } from 'constants/index';
import SlidePane from 'components/libs/SlidePane';
import { translate } from 'constants/language';
import { useStateValue } from 'context/app';
import {
  closeVerifyPhone,
  register,
  sendOTP,
  verifyOTP,
  deleteAccount
} from 'containers/Account/actions';
import values from 'object.values';
import Input from 'components/libs/Input';
import { truncatePhoneNumber, getStoreId } from 'utils/';
import { getCustomerId } from 'utils/user';
import BackIcon from 'assets/icons/arrow-back-blk.svg';
import CloseIcon from 'assets/icons/close-popup.svg';
import BackPopup from 'assets/icons/back-popup.svg';
import Button from 'components/libs/Buttons';
import ButtonBottomFixed from 'components/libs/Buttons/ButtonBottomFixed';
import EngArabicText from 'components/EngArabicText';
import { isBrowser, isMobile } from 'react-device-detect';

export default ({
  showVerifyPhoneDialog = false,
  phone,
  isLoginFlow = false,
  fromBottom = false,
  isSocialPhone,
  height,
  isPayment = false,
  isFraud = false,
  isRegistration = false,
  registerData = {},
  doClose = () => { },
  createOtpOrder,
  isDeleteUser = false,
  oppinionValue
}) => {
  const [otp, setOtp] = useState({});
  const [otpTimer, setOtpTimer] = useState(OTP_TIMER);
  const [otpError, setOtpError] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const storeId = getStoreId();
  const customerId = getCustomerId();
  const {
    account: [accountState, dispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();

  const truncatedPhone = truncatePhoneNumber(phone);
  const { envokedFrom, profilePhone } = accountState;
  const isProfile = envokedFrom === 'profile';
  const maskedPhone = truncatedPhone.replace(
    truncatedPhone.substring(2, 7),
    '*****'
  );

  const inputRefs = {};
  const handleRef = (ref, index) => {
    inputRefs[index] = ref;
  };

  const otpMachine = () => {
    if (showVerifyPhoneDialog) {
      clearInterval(window.otpTimer);
      window.otpTimer = window.setInterval(() => {
        setOtpTimer(otpTimerVal => {
          const newvalue = otpTimerVal - 1;
          if (newvalue < 0) {
            window.clearInterval(window.otpTimer);
            return 0;
          }
          return newvalue;
        });
      }, 1000);
    } else {
      window.clearInterval(window.otpTimer);
      setOtpTimer(OTP_TIMER);
    }
  };

  const sendHandler = () => {
    setOtp({});
    window.clearInterval(window.otpTimer);
    setOtpTimer(OTP_TIMER);
  };
  const resendHandle = () => {
    setOtpTimer(OTP_TIMER);
    // verifyPhone({ phone }, dispatch);
    const { email } = accountState.validatedUser || {};
    sendOTP({ phone: phone || profilePhone, email, envokedFrom }, dispatch);
    sendHandler();
    otpMachine();
  };

  const otpInputHandler = (event, index) => {
    const { value } = event.target;
    if (value.length === 4) {
      setOtp(value);
    } else {
      let trimmedValue = value;

      if (value > 0) trimmedValue = value.replace(/^0+/, '');
      if (Number(value) === 0 && value.length > 1) trimmedValue = '0';

      if (value >= 0 && value <= 9) {
        setOtp({ ...otp, [index]: trimmedValue });
      }
      if (inputRefs[index + 1] && value) {
        inputRefs[index + 1].select();
      }
    }
  };

  const handleKeyPress = (event, index) => {
    if (
      event.keyCode === 8 &&
      event.target.value === '' &&
      inputRefs[index - 1]
    ) {
      inputRefs[index - 1].select();
    }
  };

  useEffect(() => {
    otpMachine();
  }, [showVerifyPhoneDialog]);

  useEffect(() => {
    const otpValues = values(otp);
    if (otpValues.length === 4 && otpValues.indexOf('') === -1) {
      setOtpError(false);
      setEnableBtn(true);
    } else {
      setEnableBtn(false);
    }
  }, [otp]);

  const submitOtp = async () => {
    const otpValues = values(otp);
    const otpEntered = otpValues.join('');
    const { email = '' } = accountState.validatedUser || {};
    const finalPhone = phone || profilePhone;
    let response = {};
    if (isDeleteUser) {
      response = await deleteAccount(
        {
          customerId,
          storeId,
          reason: oppinionValue,
          otp: otpEntered,
          requestType: 'submit'
        },
        dispatch
      );
      if (response.data.status) {
        setOtpError(false);
        closeVerifyPhone({ success: true, isRegistration }, dispatch);
      } else {
        setOtpError(
          translate(
            'This code was incorrect. Please check the code and try again'
          )
        );
      }
    }
    if (otpEntered && finalPhone) {
      let type =
        isLoginFlow && !isSocialPhone
          ? 'LOGIN'
          : `${isRegistration ? 'REGISTRATION' : 'NORMAL'}`;

      if (isProfile) type = 'NORMAL';

      response = isPayment
        ? await createOtpOrder(otpEntered)
        : await verifyOTP(
          {
            phone: finalPhone,
            email,
            verify: true,
            otp: otpEntered,
            type,
            isRegistration,
            registerData,
            envokedFrom,
          },
          dispatch
        );
      if (response.isValid) {
        setOtpError(false);
        closeVerifyPhone({ success: true, isRegistration }, dispatch);

        if (isRegistration && response.customerResult) {
          register(
            {
              customerResult: response.customerResult,
              quote: shippingState.quote,
              isotpRegister: true
            },
            dispatch,
            shippingDispatch
          );
        }
      } else {
        setOtpError(
          translate(
            'This code was incorrect. Please check the code and try again'
          )
        );
      }
    }
  };

  const handleClose = () => {
    closeVerifyPhone({ success: false, isRegistration }, dispatch);
    doClose();
  };

  return (
    <>
      <SlidePane
        isOpen={showVerifyPhoneDialog}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        style={{ 'box-sizing': 'border-box' }}
        onRequestClose={handleClose}
        className={classnames(
          '',
          isBrowser ? 'pl-30 pr-30 pt-30 pb-6' : 'pl-15 pr-15 pt-20 pd-20',
          {
            'top-0': !isLoginFlow && !fromBottom
          }
        )}
        height={height}
        isCenter={true}
      >
        <Choose>
          <When condition={isPayment || isProfile}>
            <div className="fs-14 fw-6 lh-18 ls-064">
              {isFraud
                ? translate('Phone number verification required!')
                : translate('Verify your phone number')}
            </div>
          </When>
          <Otherwise>
            <div>
              <If condition={isMobile}>
                <a onClick={handleClose} onKeyPress={handleClose} role="link">
                  <BackIcon className="h-20 w-20 d-block rtl-reverse" />
                </a>
              </If>
              <If condition={isBrowser}>
                <div className="flex middle-xs mb-16">
                  <a onClick={handleClose} onKeyPress={handleClose} role="link">
                    <BackPopup className="d-block rtl-reverse" />
                  </a>
                  <div className="fs-12 ls-072 fw-6 ml-10 mr-10">
                    {translate('Back')}
                  </div>
                  <a
                    className="mr-0 ml-auto"
                    onClick={handleClose}
                    onKeyPress={handleClose}
                    role="link"
                  >
                    <CloseIcon className="d-block" />
                  </a>
                </div>
              </If>
            </div>
          </Otherwise>
        </Choose>

        <If condition={isLoginFlow && isMobile && !isProfile}>
          <div className="fs-14 lh-18 fw-6 c-dark-gray mt-16">
            {translate('Welcome back')}
          </div>
        </If>
        <div
          className={classnames('', {
            'fs-12 c-brown-gray ls-072 pt-4 pb-16 lh-16': isLoginFlow,
            'fs-16 c-dark-gray ls-048 pt-32 pb-32  lh-20':
              !isLoginFlow && !isPayment,
            'fs-12 c-brown-gray ls-072 pt-4 pb-16  lh-20':
              !isLoginFlow && isPayment,
            'fs-16 lh-20 ls-048 c-dark-gray': isBrowser
          })}
        >
          <Choose>
            <When condition={isPayment}>
              <EngArabicText
                engHolder="{{phoneNum}}"
                text={translate(
                  isFraud
                    ? 'Due to non receipt of multiple orders we require immediate verification of your registered mobile number to continue enjoying a hassle free shopping experience. Please enter the 4 digit SMS code sent to your registered number {{phoneNum}}'
                    : 'Please enter the 4 digit sms code sent to your registered number {{phoneNum}} to confirm the order'
                )}
                engText={maskedPhone}
              />
            </When>
            <Otherwise>
              <span>
                {translate(
                  'Please enter the 4-digit sms code sent to your registered number'
                )}
              </span>
              &nbsp;
              <span className="en-font forced-ltr d-il-block">
                {maskedPhone}
              </span>
            </Otherwise>
          </Choose>
        </div>
        <div className="flex en-font middle-xs ml-n-12 mr-n-12 forced-ltr">
          <If condition={IS_ARABIC}>
            <div className="w-20p">&nbsp;</div>
          </If>
          {new Array(4).fill(0).map((val, index) => {
            const key = index;
            return (
              <div className="w-20p">
                <Input
                  key={key}
                  inputRef={ref => {
                    handleRef(ref, index);
                  }}
                  isOtp={true}
                  value={otp[index]}
                  onChange={event => {
                    otpInputHandler(event, index);
                  }}
                  onKeyDown={event => {
                    handleKeyPress(event, index);
                  }}
                  className={classnames('fs-22 pl-0 pr-0 f-state', {
                    'fs-14': false
                  })}
                  type="number"
                  wrapClassNames="ml-12 mr-12"
                />
              </div>
            );
          })}
          <If condition={!IS_ARABIC}>
            <div className="w-20p">&nbsp;</div>
          </If>
        </div>
        <If condition={otpError}>
          <div className="pt-7 error fs-10">{otpError}</div>
        </If>
        <If condition={isLoginFlow || isPayment}>
          <Button
            className={classnames('w100p primary cap mt-25  border-none h-48', {
              'mb-24': isLoginFlow
            })}
            loadingOnDisabled={false}
            disabled={!enableBtn || accountState.otpLoading}
            onClick={submitOtp}
            id="submitOtp"
          >
            <span className="fs-14 lh-20 ls-16 fw-6 cap">
              {isDeleteUser
                ? translate('Verify And Delete')
                : translate('Verify')}
            </span>
          </Button>
        </If>
        <div
          className={classnames('fs-12 lh-16 ls-072', {
            'mb-24': isLoginFlow || fromBottom,
            'pt-32': !isLoginFlow,
            'ta-c': isBrowser
          })}
        >
          <Choose>
            <When condition={otpTimer <= 0}>
              <span
                className={classnames('c-brown-gray', { 'd-block': isBrowser })}
              >
                {translate('Didn’t receive a code?')}
              </span>

              <span
                className={classnames(
                  'fw-6 c-dark-gray bb-brand-dashed pb-4 ml-5 mr-5',
                  { 'fs-14 lh-16 pt-4': isBrowser }
                )}
                role="button"
                onClick={resendHandle}
                onKeyPress={resendHandle}
              >
                {translate('Resend Again')}
              </span>
            </When>
            <When condition={otpTimer}>
              <span role="button" className="c-brown-gray">
                {translate('Resend code in')}
                <span className="en-font forced-ltr"> {otpTimer} </span>
                {translate('sec')}
              </span>
            </When>
          </Choose>
        </div>
        <If condition={!isLoginFlow && !isPayment}>
          <ButtonBottomFixed
            disabled={!enableBtn || accountState.otpLoading}
            loadingOnDisabled={false}
            onClick={submitOtp}
            className="cap"
            id="submitOtp"
            hideBorderTop={true}
          >
            {translate('Verify')}
          </ButtonBottomFixed>
        </If>
      </SlidePane>
    </>
  );
};
