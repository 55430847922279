import { isBrowser } from 'react-device-detect';
import axios from 'axios';
import { getCookieValue, setCookie, getJWT, deleteCookie } from 'utils';
import { GET_REFRESH_TOKEN, LOGOUT_USER } from 'constants/api';
import { logoutStorage, getStorage } from './storage';
import {
  JWT_COOKIE_NAME,
  DEVICE_ID_COOKIE_NAME,
  UID_COOKIE_NAME,
  REFRESH_TOKEN
} from '../constants';
import { generateUUID } from './analytics';
import {
  // decodeJWT,
  getCustomerId
  // isTwoMinutesBeforeExpiration
  // getXHeaderToken
} from './user';

const axiosOptions = {
  timeout: 120000,
  headers: {
    'X-Source': isBrowser ? 'msite' : 'msite',
    'X-Client-Version':
      process.env.TAG_NAME || process.env.BRANCH || 'development'
  }
};
const axiosMobileInstance = axios.create(axiosOptions);
axiosMobileInstance.interceptors.request.use(
  async config => {
    const modifiedConfig = { ...config };
    const consulFlag = await getStorage('config');
    const sessionValidation =
      consulFlag?.sessionInvalidationConfig?.['msite_refresh_token_enable'];
    if (sessionValidation && sessionValidation === false) {
      deleteCookie(JWT_COOKIE_NAME);
      deleteCookie(REFRESH_TOKEN);
      deleteCookie(UID_COOKIE_NAME);
      deleteCookie('r_email');
      await logoutStorage();
      return '';
    }
    if (
      modifiedConfig?.url.includes('recommendation') ||
      modifiedConfig?.url.includes('plp-banners')
    ) {
      return '';
    }
    if (
      modifiedConfig.headers.Token ||
      modifiedConfig.headers['X-Header-Token']
    ) {
      const token = getJWT();
      // const refreshToken = getCookieValue(REFRESH_TOKEN);
      let deviceId = getCookieValue(DEVICE_ID_COOKIE_NAME);
      if (!deviceId) {
        deviceId = generateUUID();
        setCookie(DEVICE_ID_COOKIE_NAME, deviceId, 365); // Store for 365 days
      }
      if (token) {
        const excludedUrls = [
          '/refresh/access/token',
          'recommendation',
          'plp-banners',
          'address'
        ];
        if (modifiedConfig?.url?.includes('address')) {
          modifiedConfig.headers['device-id'] = deviceId;
        }
        if (!excludedUrls.some(url => modifiedConfig?.url.includes(url))) {
          modifiedConfig.headers.Token = token;
          modifiedConfig.headers['device-id'] = deviceId;
        }
      } else if (
        modifiedConfig?.url.includes('login') ||
        modifiedConfig?.url.includes('address')
      ) {
        modifiedConfig.headers['device-id'] = deviceId;
      }
    }

    return modifiedConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosMobileInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.headers.Token
    ) {
      originalRequest._retry = true; // will retry the original request
      try {
        const accessToken = getCookieValue(JWT_COOKIE_NAME);
        if (accessToken) {
          let deviceId = getCookieValue(DEVICE_ID_COOKIE_NAME);
          const refreshToken = getCookieValue(REFRESH_TOKEN);
          if (!deviceId) {
            deviceId = generateUUID();
            setCookie(DEVICE_ID_COOKIE_NAME, deviceId, 365);
          }
          const customerId = getCustomerId();

          // Modify the original request headers directly
          originalRequest.headers = {
            ...originalRequest.headers,
            Token: accessToken,
            'device-id': deviceId,
            'refresh-token': refreshToken
          };

          const response = await axiosMobileInstance.post(
            GET_REFRESH_TOKEN(),
            { customerId },
            {
              headers: originalRequest.headers
            }
          );

          const newAccessToken = response?.data?.response?.accessToken;

          setCookie(JWT_COOKIE_NAME, newAccessToken, 30);

          // Update the original request with the new access token
          originalRequest.headers.Token = newAccessToken;

          if (!newAccessToken) {
            // Handle the error only if the refresh token call fails
            // Modify the original request headers directly for logout
            originalRequest.headers = {
              ...originalRequest.headers,
              Token: accessToken,
              'device-id': deviceId,
              'refresh-token': refreshToken
            };

            const resLogout = await axios.post(
              LOGOUT_USER(),
              { customerId },
              {
                headers: originalRequest.headers
              }
            );
            if (resLogout) {
              console.log('logout api called');
            }

            deleteCookie(JWT_COOKIE_NAME);
            deleteCookie(REFRESH_TOKEN);
            deleteCookie(UID_COOKIE_NAME);
            deleteCookie('r_email');
            await logoutStorage();
          }
        }
      } catch (errors) {
        console.error(
          'An unexpected error occurred:Please Login Again!',
          errors
        );
      }
    }

    return Promise.reject(error);
  }
);

export default axiosMobileInstance;
